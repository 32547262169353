/* eslint-disable no-console */
// TODO: Replace import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';

import config from '../config';

const useFlags = () => {
  // TODO: replace const realFlags = useLDFlags();
  const [flags] = useState(() => {
    let flagOverrides = {};

    if (process.env.NODE_ENV === 'development') {
      try {
        flagOverrides = require('flags.json');
      } catch (e) {
        /* do nothing */
      }
    }

    // TODO: Remove this
    if (process.env.NODE_ENV === 'production') {
      try {
        flagOverrides = require('flags_production.json');
        flagOverrides = {
          ...flagOverrides,
          mockPolicies: config.mockPolicies === 'true' ? true : false
        };
      } catch (e) {
        /* do nothing */
      }
    }

    // TODO: Replace return { ...realFlags, ...flagOverrides };
    return { ...flagOverrides };
  });

  return flags;
};

export default useFlags;

import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useResponsive } from 'utils';

import Icon from '../Icon';
import Button from '../Button';
import './Dialog.css';

const setBodyStyles = (show, hide) => {
  if (show && hide) {
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = '10px';
  } else {
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
  }
};

const Dialog = ({
  show,
  style,
  wrapperStyle,
  headingStyle,
  contentStyle,
  actionsStyle,
  heading,
  actions,
  children,
  className,
  onClose,
  ariaLabel,
  invokingElementId,
  hide = true
}) => {
  const dialogRef = useRef(null);
  const { width } = useResponsive();

  const isTopModal = () => {
    return dialogRef.current === document.body.querySelector('.alf-dialog');
  };
  const invokingElement = invokingElementId
    ? document.getElementById(invokingElementId)
    : document.activeElement;
  const handledClose = () => {
    if (invokingElement) {
      invokingElement.focus();
    }
    onClose();
  };

  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      handledClose();
    }
  };
  const getFocusableElements = () => {
    return dialogRef.current.querySelectorAll(
      'a[href], a[onClick], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select, [tabindex = "0"]'
    );
  };

  // eslint-disable-next-line consistent-return
  const handleTabKey = (e) => {
    if (dialogRef.current) {
      const focusableModalElements = getFocusableElements();
      const firstElement = focusableModalElements[0];
      const lastElement = focusableModalElements[
        focusableModalElements.length - 1
      ].disabled
        ? focusableModalElements[focusableModalElements.length - 2]
        : focusableModalElements[focusableModalElements.length - 1];

      if (
        !Array.from(focusableModalElements).includes(document.activeElement)
      ) {
        firstElement.focus();
        return e.preventDefault();
      }
      if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        return e.preventDefault();
      }

      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        return e.preventDefault();
      }
    }
  };
  const pressEsc = (e) => {
    if (e.keyCode === 27 && isTopModal()) {
      e.stopPropagation();
      handledClose();
    }
  };

  const keyListenersMap = new Map([[27, pressEsc], [9, handleTabKey]]);
  useEffect(() => {
    const keyListener = (e) => {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    };

    setBodyStyles(show, hide);
    document.addEventListener('keydown', keyListener);

    return () => document.removeEventListener('keydown', keyListener);
  }, [show, onClose, keyListenersMap, hide]);

  const dialog = (
    <CSSTransition in={show} unmountOnExit timeout={150}>
      <div
        className={clsx('alf-dialog', width < 415 && 'fullscreen', className)}
        style={style}
        role="dialog"
        ref={dialogRef}
        aria-modal="true"
        aria-label={ariaLabel}
      >
        <div
          className="backdrop"
          onClick={handleClickOutside}
          role="presentation"
        />
        <div className="wrapper" style={wrapperStyle}>
          <div className="heading" style={headingStyle}>
            <div>{heading}</div>
            <div>
              <Button
                ariaLabel="Dismiss"
                id="close-dialog-button"
                onClick={handledClose}
                icon={
                  <Icon name="times" fontSize={12} style={{ margin: '4px' }} />
                }
              />
            </div>
          </div>
          <div className="content" style={contentStyle}>
            {children}
          </div>
          {actions && (
            <div className="actions" style={actionsStyle}>
              {actions}
            </div>
          )}
        </div>
      </div>
    </CSSTransition>
  );
  return ReactDOM.createPortal(dialog, document.body);
};

Dialog.propTypes = {
  show: PropTypes.bool,
  heading: PropTypes.any,
  actions: PropTypes.any,
  children: PropTypes.any,
  style: PropTypes.object,
  wrapperStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  actionsStyle: PropTypes.object,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
  invokingElementId: PropTypes.string,
  hide: PropTypes.bool
};

export default Dialog;

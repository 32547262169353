import React from 'react';
import PropTypes from 'prop-types';

import './Search.css';

import { Icon } from '..';

const Search = ({ name, onChange, onSubmit, placeholder, value }) => {
  const onEnter = (e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };
  return (
    <span className="alf-search-span">
      <div className="alf-search-div">
        <Icon
          name="search"
          fontSize={20}
          color="grey"
          onClick={onSubmit}
          style={{ marginTop: 0 }}
        />
      </div>
      <input
        type="text"
        className="alf-search-input"
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onKeyDown={onEnter}
      ></input>
    </span>
  );
};

Search.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

export default Search;

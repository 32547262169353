import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useResponsive, usePolicy } from 'utils';

import ClaimStatusTracker from './ClaimStatusTracker';
import ClaimDetailsHeader from './ClaimDetailsHeader';
import ClaimDetailsAssistance from './ClaimDetailsAssistance';
import ClaimInformation from './ClaimInformation';
import { Card, Row, Col, ExpansionPanel } from '../alf-design';

const styles = {
  headerExp: {
    border: 'solid #6648DC',
    borderWidth: '0 0 6px 0',
    marginBottom: 8
  },
  header: { marginBottom: 0 },
  trackerCard: { height: '100%' }
};

const ClaimDetails = ({ claim, index, expanded, handleChange }) => {
  const { screenIsUp, screenIsDown } = useResponsive();
  const { selectedPolicy } = usePolicy();
  const policyholder = selectedPolicy ? selectedPolicy.insured : {};

  return screenIsDown('lg') ? (
    <ExpansionPanel
      className="claim-details"
      expanded={expanded === index}
      key={claim.claimNumber}
      onChange={handleChange(index)}
      headerStyle={
        screenIsDown('md') && expanded === index
          ? styles.headerExp
          : styles.header
      }
      header={
        <ClaimDetailsHeader
          claim={claim}
          border={screenIsUp('md')}
          expanded={expanded}
          index={index}
        />
      }
    >
      <div style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col md={10} style={{ marginBottom: '16px' }}>
            <ClaimInformation policyholder={policyholder} claim={claim} />
          </Col>
          <Col md={14}>
            <ClaimStatusTracker orientation="vertical" claim={claim} />
          </Col>
        </Row>
        <ClaimDetailsAssistance align="center" />
      </div>
    </ExpansionPanel>
  ) : (
    <Row gutter={16}>
      <Col xxl={5} style={{ marginBottom: screenIsDown('xl') ? '36px' : 0 }}>
        <ClaimInformation
          policyholder={policyholder}
          claim={claim}
          withAssistance={screenIsUp('md') && screenIsDown('xl')}
        />
        {screenIsUp('xl') && <ClaimDetailsAssistance align="left" />}
      </Col>
      <Col xxl={19}>
        <Card style={styles.trackerCard}>
          <ClaimStatusTracker orientation="horizontal" claim={claim} />
        </Card>
      </Col>
    </Row>
  );
};

ClaimDetails.propTypes = {
  claim: PropTypes.object,
  index: PropTypes.number,
  expanded: PropTypes.number,
  handleChange: PropTypes.func
};

export default withRouter(ClaimDetails);

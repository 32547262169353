import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, Typography } from '../alf-design';

const styles = {
  actions: {
    alignSelf: 'center',
    marginTop: 25,
    marginBottom: 100
  },
  wrapper: {
    width: 575,
    minHeight: 475
  },
  content: {
    minHeight: 435,
    display: 'flex',
    verticalAlign: 'middle'
  },
  divText: {
    width: '85%',
    margin: 'auto'
  },
  text: {
    fontSize: 16,
    textAlign: 'left'
  }
};

const LoadingMessage = ({ show, onClose }) => {
  return (
    <Dialog
      id="loading-message-popup"
      show={show}
      onClose={onClose}
      actionsStyle={styles.actions}
      wrapperStyle={styles.wrapper}
      hide={false}
    >
      <div style={styles.content}>
        <div style={styles.divText}>
          <Typography type="paragraph" style={styles.text}>
            Processing...
          </Typography>
          <Typography type="paragraph" style={styles.text}>
            Please wait before continuing or closing your browser.
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};

LoadingMessage.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func
};

export default LoadingMessage;

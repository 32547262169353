import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Typography, Row, Col, Label } from '../alf-design';

const ClaimDetailsHeader = ({ claim, border, expanded, index }) =>
  !border ? (
    <div>
      <Row style={{ marginRight: '8px' }}>
        <Col xs={16}>
          <Typography type="subtitle2" wordNoWrap>
            {claim.claimNumber}
          </Typography>
        </Col>
        <Col xs={8}>
          <Label
            color={claim.status === 'Open' ? 'green' : ''}
            text={claim.status}
          />
        </Col>
      </Row>
      <Row>
        <Typography type="caption2">
          Date of Loss: {moment(claim.lossDate).format('L')}
        </Typography>
      </Row>
    </div>
  ) : (
    <div style={{ display: 'flex' }}>
      <div
        style={
          expanded === index
            ? {
                border: 'solid #6647DC',
                borderWidth: '0 0 6px 0',
                height: '40px',
                marginRight: '8px'
              }
            : { display: 'unset', marginRight: '8px' }
        }
      >
        <Typography
          type="caption4"
          wordNoWrap
          style={{
            whiteSpace: 'nowrap'
          }}
        >
          {claim.claimNumber}
        </Typography>
      </div>
      <Typography
        type="caption2"
        style={{ whiteSpace: 'nowrap', paddingRight: '16px' }}
      >
        Date of Loss: {moment(claim.lossDate).format('L')}
      </Typography>

      <Label
        color={claim.status === 'Open' ? 'green' : 'grey'}
        text={claim.status}
      />
    </div>
  );

ClaimDetailsHeader.propTypes = {
  claim: PropTypes.object.isRequired,
  border: PropTypes.bool,
  expanded: PropTypes.number,
  index: PropTypes.number
};

ClaimDetailsHeader.defaultProps = {
  border: false
};

export default ClaimDetailsHeader;

import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStorage } from 'utils';

import './Select.css';

import Typography from '../Typography';

import { Icon } from '..';

const styles = {
  placeholder: {
    cursor: 'pointer',
    pointerEvents: 'none',
    color: '#acaaa5',
    position: 'absolute',
    top: 7,
    left: 8,
    zIndex: 1
  },
  icon: {
    cursor: 'pointer',
    pointerEvents: 'none',
    position: 'absolute',
    top: '13px',
    right: '8px',
    zIndex: 1
  }
};

const Select = ({
  options,
  onChange,
  value,
  name,
  fullWidth,
  success,
  error,
  placeholder,
  autoComplete,
  disabled
}) => {
  const [isMobile] = useStorage('isMobile', false);
  const [selected, setSelected] = useState(false);

  const handleSelect = (e) => {
    onChange(e);
  };

  useLayoutEffect(() => {
    setSelected(!!value);
  }, [value]);

  return (
    <div style={{ position: 'relative' }}>
      <Icon
        ariaHidden
        alt="Toggle selected"
        name="chevronDown"
        color="#5B564D"
        fontSize={12}
        style={styles.icon}
      />
      {!selected && (
        <Typography type="caption1" component="span" style={styles.placeholder}>
          {placeholder}
        </Typography>
      )}
      <select
        id={`header-${name}`}
        name={name}
        disabled={disabled}
        value={value}
        autoComplete={autoComplete}
        onChange={handleSelect}
        onBlur={handleSelect}
        className={clsx(
          'alf-select',
          fullWidth && 'fullwidth',
          success && 'success',
          error && 'error',
          isMobile && 'no-focus'
        )}
      >
        <option disabled value=""></option>
        {options &&
          options.map((item) => (
            <option key={item.id} name={item.title} value={item.id}>
              {item.title}
            </option>
          ))}
      </select>
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, title: PropTypes.string })
  ),
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};
Select.defaultProps = {
  disabled: false,
  fullWidth: true,
  maxHeight: 200
};

export default Select;

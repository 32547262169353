import React from 'react';

function useForm(initialState, validate, validateOnChange = false) {
  const [values, setValues] = React.useState(initialState);
  const [errors, setErrors] = React.useState({});
  const [refs, setRef] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const [isSubmitting, setSubmitting] = React.useState(false);

  function initForm(initValues) {
    const newValues = {
      ...values,
      ...initValues
    };
    const fieldsRefs = {};
    Object.keys(initialState).forEach((name) => {
      fieldsRefs[name] = React.createRef();
    });
    setRef(fieldsRefs);
    setValues(newValues);
  }

  function setFormValues(valuesToSet, validateForm = true) {
    const newValues = {
      ...values,
      ...valuesToSet
    };
    let newTouched = { ...touched, ...valuesToSet };
    Object.keys(valuesToSet).forEach((key) => {
      newTouched[key] = true;
    });
    if (validateForm) {
      const validationErrors = validate(newValues);
      setErrors(validationErrors);
      setTouched(newTouched);
    }
    setValues(newValues);
  }

  function handleChange(event) {
    const newValues = {
      ...values,
      [event.target.name]: event.target.value
    };
    const newTouched = {
      ...touched,
      [event.target.name]: true
    };
    setValues(newValues);
    setTouched(newTouched);
    if (validateOnChange) {
      const validationErrors = validate(newValues);
      setErrors(validationErrors);
    }
  }
  const focusToFirstError = (firstError) => {
    const inputRef = refs[firstError];
    if (inputRef && inputRef.current && inputRef.current.focus) {
      if (inputRef.current.localName === 'div') {
        inputRef.current.firstChild.focus();
      } else {
        inputRef.current.focus();
      }
    } else {
      window.scrollTo(0, 0);
    }
  };

  function handleBlur() {
    const validationErrors = validate(values);
    setErrors(validationErrors);
  }

  function handleSubmit(event, callback) {
    event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    setTouched({
      ...touched,
      ...validationErrors
    });
    const noErrors = Object.keys(validationErrors).length === 0;
    if (noErrors) {
      setSubmitting(true);
      callback(() => {
        setSubmitting(false);
      });
    } else {
      setSubmitting(false);

      const firstError = Object.keys(validationErrors)[0];
      focusToFirstError(firstError);
    }
  }
  return {
    initForm,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    setFormValues,
    touched,
    isSubmitting,
    refs
  };
}

export default useForm;

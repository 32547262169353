import React, { useLayoutEffect } from 'react';
import { useAuth0 } from 'utils';

const RestartLogin = () => {
  const { loginWithRedirect } = useAuth0();

  useLayoutEffect(() => {
    loginWithRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default RestartLogin;

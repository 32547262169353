import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './Form.css';
import Typography from '../Typography';

const FormField = ({
  fullWidth,
  label,
  labelAriaHidden,
  ariaLabel,
  error,
  children,
  icon,
  labelFor
}) => (
  <div className={clsx('alf-formfield', fullWidth && 'fullwidth')}>
    <div>
      <Typography
        type="caption1"
        component="label"
        htmlFor={labelFor ? labelFor : children.props.id}
        ariaLabel={ariaLabel}
        ariaHidden={labelAriaHidden}
      >
        {label}
      </Typography>
      {icon}
    </div>
    {children}
    <div className="feedback">
      {error && (
        <Typography type="error" component="p">
          {error}
        </Typography>
      )}
    </div>
  </div>
);
FormField.propTypes = {
  fullWidth: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  labelAriaHidden: PropTypes.string,
  ariaLabel: PropTypes.string,
  error: PropTypes.string,
  children: PropTypes.any,
  icon: PropTypes.object,
  labelFor: PropTypes.string
};

FormField.defaultProps = {
  fullWidth: true
};

export default FormField;

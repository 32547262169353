import React, { useState } from 'react';
import Search from 'components/alf-design/Search';
import { withApi } from 'utils';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';

import { publicFooterLogo } from '../../assets/img';
import { Card } from '../alf-design';

const AdminPage = ({ history, api, isSearchView, setSearchView }) => {
  const styles = {
    wrapper: {
      display: 'flex',
      placeContent: 'center'
    },
    card: {
      width: 530,
      display: 'inline',
      textAlign: 'center'
    },
    search: {
      width: `${isSearchView ? '75%' : '100%'}`,
      display: 'inline-block'
    },
    errorPolicy: {
      background: '#FEF7F6 0% 0% no-repeat padding-box',
      padding: 12,
      fontSize: 'min(16rem, calc(16px * 1.5))',
      color: '#DC2A2A',
      marginBottom: 16
    }
  };
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const onSubmit = (e) => {
    if (isSearchView) {
      setSearchView(false);
    }
    history.replace(`/?policy=${value}`);
  };

  return (
    <div style={styles.wrapper}>
      {isSearchView ? (
        <Card style={styles.card}>
          <img
            src={publicFooterLogo}
            width="225"
            alt="Slide Insurance logo"
            id="slide-insurance-logo"
            className="make_payment__logo"
          />
          <div style={styles.search}>
            <Search
              name="searchPolicy"
              onChange={handleChange}
              onSubmit={value ? onSubmit : () => {}}
              placeholder="Search by Policy Number"
              value={value}
            />
          </div>
        </Card>
      ) : (
        <div style={styles.search}>
          <Search
            name="searchPolicy"
            onChange={handleChange}
            onSubmit={value ? onSubmit : () => {}}
            placeholder="Search by Policy Number"
            value={value}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(withApi(AdminPage));

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './Button.css';
import Spinner from '../Spinner';

const TYPES = ['default', 'primary', 'secondary'];

const Button = ({
  id,
  style,
  onClick,
  onFocus,
  onBlur,
  className,
  type,
  fullWidth,
  icon,
  size,
  withLoading,
  htmlType,
  disabled,
  children,
  onMouseOver,
  whiteBorder,
  ariaLabel,
  ariaDescribedBy,
  link,
  to
}) => {
  return link ? (
    <a
      type={htmlType}
      disabled={disabled}
      id={id}
      data-testid={id}
      tabIndex="0"
      aria-label={ariaLabel}
      style={style}
      {...(!disabled && { onClick })}
      onKeyPress={(e) => {
        if (onClick && e.key === 'Enter' && !disabled) {
          onClick(e);
        }
      }}
      className={clsx(
        icon && !children ? 'alf-button-icon' : 'alf-button',
        className,
        type,
        whiteBorder && 'white-border',
        fullWidth && 'fullwidth',
        size,
        withLoading ? 'loading' : ''
      )}
      href={to}
    >
      {icon}
      {withLoading ? <Spinner color={type} /> : <span>{children}</span>}
    </a>
  ) : (
    <button
      id={id}
      data-testid={id}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedBy}
      style={style}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseOver={onMouseOver}
      className={clsx(
        icon && !children ? 'alf-button-icon' : 'alf-button',
        className,
        type,
        whiteBorder && 'white-border',
        fullWidth && 'fullwidth',
        size,
        withLoading ? 'loading' : ''
      )}
      type={htmlType}
      disabled={disabled}
    >
      {icon}
      {withLoading ? <Spinner color={type} /> : <span>{children}</span>}
    </button>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  withLoading: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  icon: PropTypes.any,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseOver: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.oneOf(TYPES),
  disabled: PropTypes.bool,
  style: PropTypes.object,
  htmlType: PropTypes.string,
  size: PropTypes.string,
  whiteBorder: PropTypes.bool,
  ariaLabel: PropTypes.string,
  ariaDescribedBy: PropTypes.string
};

Button.defaultProps = {
  htmlType: 'button',
  type: 'default',
  disabled: false,
  fullWidth: false,
  withLoading: false,
  size: 'md',
  whiteBorder: false
};

export default Button;

import React from 'react';
import PropTypes from 'prop-types';
import { formats } from 'utils';

import { Typography, Row, Col, Link } from '../alf-design';
import ClaimDetailsAssistance from './ClaimDetailsAssistance';

const ClaimInformation = ({
  claim: { status, managerName, managerEmail, managerPhone, causeOfLoss },
  withAssistance
}) => (
  <Row>
    {status !== 'Closed' && (
      <Col xs={24} xl={5} xxl={24} style={{ marginBottom: 10 }}>
        <Typography type="caption1" style={{ fontWeight: '800' }}>
          Claims Manager
        </Typography>
        <Typography type="caption1">{managerName || 'Pending'}</Typography>
        {managerPhone && (
          <Link
            type="primary"
            href={`tel:${formats.phoneFormat(managerPhone)}`}
          >
            {formats.phoneFormat(managerPhone)}
          </Link>
        )}
        <br />
        <Link type="primary" href={`mailto:${managerEmail}`}>
          {managerEmail}
        </Link>
      </Col>
    )}
    <Col xs={24} xl={5} xxl={24} style={{ marginBottom: 10 }}>
      <Typography type="caption1" style={{ fontWeight: '800' }}>
        Cause of Loss{' '}
      </Typography>
      <Typography type="caption1">{causeOfLoss}</Typography>
    </Col>
    {withAssistance && (
      <Col xs={24} xl={5} xxl={24} style={{ marginBottom: 10 }}>
        <ClaimDetailsAssistance />
      </Col>
    )}
  </Row>
);

ClaimInformation.propTypes = {
  claim: PropTypes.object,
  withAssistance: PropTypes.bool
};

export default ClaimInformation;

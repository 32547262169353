/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { logo } from 'assets/img';
import { Typography, Icon, Button, Link } from 'components/alf-design';
import { Row, Col } from 'components/alf-design/Grid';
import { useResponsive } from 'utils';
import './AppBar.css';

const styles = {
  profileImg: {
    height: 30,
    width: 30,
    borderRadius: 22,
    verticalAlign: 'middle',
    cursor: 'pointer',
    color: '#6648DC'
  }
};
const AppBar = ({ onHelp, onLogout, onUserProfile, onSideBar, onLogo }) => {
  const { screenIsUp, screenIsDown } = useResponsive();
  return (
    <header className="alf-app-bar" role="banner">
      <Link href="#main-content" className="alf-skip-link">
        Skip to Content
      </Link>
      <nav>
        <div className="left">
          {screenIsDown('lg') && (
            <Button
              ariaLabel="Menu"
              style={{ margin: '0px 26px' }}
              id="Menu-Icon"
              onClick={onSideBar}
              icon={
                <Icon
                  name="menu"
                  fontSize={24}
                  color="#6647DC"
                  style={{ margin: '4px' }}
                />
              }
            />
          )}
          <a
            id="slide-logo"
            aria-label="Slide Insurance dashboard"
            onClick={onLogo}
          >
            <img src={logo} alt="Slide Insurance dashboard" className="logo" />
          </a>
        </div>
        {screenIsUp('lg') && (
          <Row className="right">
            <Col
              style={{
                padding: '0px 16px',
                marginLeft: 0
              }}
            >
              <Button
                style={{ padding: 0 }}
                id="User-Profile-Icon"
                ariaLabel="My profile"
                onClick={onUserProfile}
                link
                icon={
                  <Icon name="userCircleEmpty" fontSize={27} color="#6647DC" />
                }
              />
            </Col>
            <Col
              style={{
                padding: '0px 16px',
                marginLeft: 0,
                borderLeft: '2px solid #6647DC'
              }}
            >
              <Button
                id="app-bar-help"
                ariaLabel="Frequently asked questions"
                style={{ padding: 0 }}
                onClick={onHelp}
                link
                icon={
                  <Icon name="questionCircle" fontSize={27} color="#6647DC" />
                }
              />
            </Col>
            <Col className="logout" style={styles.columns}>
              <Typography
                id="app-bar-logout"
                type="linkButton"
                component="button"
                onClick={onLogout}
                withTab
              >
                Log Out
              </Typography>
            </Col>
          </Row>
        )}
      </nav>
    </header>
  );
};

AppBar.propTypes = {
  onHelp: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onUserProfile: PropTypes.func.isRequired,
  onSideBar: PropTypes.func.isRequired,
  onLogo: PropTypes.func
};

export default AppBar;

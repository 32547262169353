import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Button, Row, Col } from '../alf-design';

const styles = {
  wrapper: {
    maxWidth: 800,
    margin: '0 auto',
    textAlign: 'center'
  },
  error: {
    textAlign: 'center',
    fontSize: 'min(170rem, calc(170px * 1.5))'
  },
  oh: {
    textAlign: 'center',
    fontSize: 'min(59rem, calc(59px * 1.5))',
    marginBottom: 30
  },
  title: {
    marginTop: 32,
    marginBottom: 16
  },
  message: {
    textAlign: 'center'
  },
  button: {
    marginTop: 32
  }
};

const NotFound = ({ history }) => (
  <Row gutter={24} style={styles.wrapper}>
    <Col md={12}>
      <Typography type="heading1" component="h1" style={styles.error}>
        404
      </Typography>
      <Typography type="heading2" component="h2" style={styles.oh}>
        uh - oh!
      </Typography>
    </Col>
    <Col md={12}>
      <Typography type="heading3" component="h3" style={styles.title}>
        Page Not Found
      </Typography>
      <div>
        <Typography type="paragraph">
          Looks like this page doesn’t exist.
        </Typography>
        <Typography type="paragraph">
          Please return to the dashboard and try again.
        </Typography>
      </div>
      <Button type="primary" style={styles.button} link to="/">
        Back to Dashboard
      </Button>
    </Col>
  </Row>
);

NotFound.propTypes = {
  history: PropTypes.object.isRequired
};

export default NotFound;

import React from 'react';
import PropTypes from 'prop-types';

import './Form.css';

const Form = ({ onSubmit, fullWidth, children }) => (
  <form onSubmit={onSubmit} noValidate style={fullWidth && { width: '100%' }}>
    {children}
  </form>
);

Form.propTypes = {
  onSubmit: PropTypes.func,
  fullWidth: PropTypes.bool,
  children: PropTypes.any
};

export default Form;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import StepperContext from './StepperContext';

import { Typography, Icon } from '..';

const Step = ({
  lastStep,
  statusSeparator,
  active,
  title,
  hiddenContent,
  children
}) => {
  const { orientation } = React.useContext(StepperContext);
  const borderStyle = statusSeparator
    ? '1px solid #1e824c'
    : '1px solid #b8860d';

  return (
    <li className={clsx('alf-step', orientation)} style={{ flexBasis: '100%' }}>
      <span
        className={clsx(
          'step-label',
          !lastStep &&
            orientation === 'horizontal' &&
            (statusSeparator ? 'active' : 'inactive')
        )}
      >
        <span className="step-icon-container">
          {active ? (
            <Icon
              name="checkCircleFull"
              color="#34D399"
              ariaLabel={`${title} completed`}
              tabIndex={-1}
              role="img"
            />
          ) : (
            <Icon
              name="circle"
              color="#6648DC"
              ariaLabel={`${title} pending`}
              tabIndex={-1}
              role="img"
            />
          )}
        </span>
        <span className="step-label-title">
          <Typography
            component="span"
            type="caption4"
            style={{ color: active ? '#34D399' : '#6648DC' }}
          >
            {title}
          </Typography>
        </span>
      </span>
      <div
        className="step-content"
        style={{
          borderLeft:
            orientation === 'vertical' && !lastStep ? borderStyle : 'unset',
          minHeight: !lastStep ? '36px' : '0px'
        }}
      >
        {!hiddenContent && children}
      </div>
    </li>
  );
};

Step.propTypes = {
  title: PropTypes.string,
  active: PropTypes.bool,
  lastStep: PropTypes.bool,
  statusSeparator: PropTypes.bool,
  children: PropTypes.any,
  hiddenContent: PropTypes.bool
};

Step.defaultProps = {
  active: false,
  lastStep: false,
  hiddenContent: false
};

export default Step;

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { customTypes, formats } from 'utils';

import './SelectPolicy.css';

import { Icon, Typography } from '..';

const SelectPolicy = ({ id, policies, selectedPolicy, onSelect }) => {
  const [selectPolicy, setSelectPolicy] = useState(false);
  const ref = useRef();

  const handleClick = (e) => {
    if (selectPolicy && ref.current && !ref.current.contains(e.target)) {
      setSelectPolicy(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 27) {
        e.stopPropagation();
        setSelectPolicy(false);
      }
    };
    document.addEventListener('click', handleClick);
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleSelect = (policy) => {
    onSelect(policy);
    setSelectPolicy(false);
  };

  return (
    <div id={id} className="alf-select-policy">
      <button
        aria-label="Select policy"
        className="select-text"
        onClick={() => setSelectPolicy(true)}
        aria-haspopup="true"
        aria-controls="popover-select-policy"
        aria-expanded={!!selectPolicy}
      >
        {`You have ${policies.length} Policies`}
        <Icon
          ariaHidden
          name={!selectPolicy ? 'chevronDown' : 'chevronUp'}
          color="#6647DC"
          fontSize={10}
          style={{ verticalAlign: 'middle', marginLeft: 8 }}
        />
      </button>
      {selectPolicy && (
        <ul ref={ref} className="popover" id="popover-select-policy">
          {policies.map((policy, index) => (
            <li key={index}>
              <div
                id={`select-policy-row-${index}`}
                key={policy.policyNumber}
                className={clsx(
                  'row',
                  selectedPolicy.policyNumber === policy.policyNumber &&
                    'selected'
                )}
                onClick={() => handleSelect(policy)}
                onKeyPress={() => handleSelect(policy)}
                role="button"
                tabIndex={0}
                aria-label={
                  selectedPolicy.policyNumber === policy.policyNumber
                    ? `Policy selected. ${policy.propertyStreet}, ${
                        policy.propertyCity
                      }, ${policy.propertyState} ${formats.zipFormat(
                        policy.propertyZip
                      )}. Policy number: ${policy.policyNumber}. Product: ${
                        policy.type
                      }. Status:  ${policy.status} `
                    : null
                }
              >
                <Typography
                  type="caption1"
                  style={{ fontWeight: 600, color: '#5B564D' }}
                >
                  {`${policy.propertyStreet}, ${policy.propertyCity}, ${
                    policy.propertyState
                  } ${formats.zipFormat(policy.propertyZip)}`}
                </Typography>
                <Typography
                  type="paragraph"
                  component="span"
                  className="select-policy-row-desc"
                >
                  <b>Policy Number: </b>
                  {` ${policy.policyNumber} | `}
                  <b>Product: </b>
                  {` ${policy.type} | `}
                  <b>Status: </b>
                  {policy.status}
                </Typography>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

SelectPolicy.propTypes = {
  id: PropTypes.string,
  policies: PropTypes.arrayOf(customTypes.policy),
  selectedPolicy: PropTypes.object,
  onSelect: PropTypes.func
};

export default SelectPolicy;

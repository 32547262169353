import moment from 'moment';

export const dateFormat = (date) =>
  moment(date)
    .utc()
    .format('l');

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});
export const moneyFormat = (money) => formatter.format(money);
export const moneyWithoutDecimalsFormat = (money) =>
  formatter.format(money).replace(/\D00$/, '');

export const phoneFormat = (number) => {
  if (number) {
    const str = number.toString().replace(/\D+/g, '');
    const match = str.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : number;
  }
  return '';
};

export const zipFormat = (zip) => {
  let zipCode = '';
  if (zip) {
    zipCode = zip.match(/\b\d{5}\b/);
  }
  return zipCode;
};

export const address = ({ street, city, state, zip }) =>
  `${street}, ${city}, ${state} ${zipFormat(zip)}`;

export default {
  dateFormat,
  moneyFormat,
  moneyWithoutDecimalsFormat,
  phoneFormat,
  zipFormat,
  address
};

/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withApi, customTypes } from 'utils';

import config from '../../config';
import { Typography, Card, Spinner } from '../alf-design';

const styles = {
  resendErrorLink: {
    marginTop: '12px',
    cursor: 'pointer',
    textDecoration: 'underline #DC2A2A',
    textAlign: 'center',
    maxWidth: 'max-content'
  },
  resendSentLink: {
    marginTop: '12px',
    cursor: 'pointer',
    textDecoration: 'underline #333333',
    textAlign: 'center',
    maxWidth: 'max-content'
  },
  resendErrorCard: {
    margin: '24px',
    marginTop: '24px',
    backgroundColor: '#FF00001A',
    boxShadow: 'initial',
    borderRadius: '0px'
  },
  resendSentCard: {
    margin: '24px',
    marginTop: '24px',
    backgroundColor: '#0039721A',
    boxShadow: 'initial',
    borderRadius: '0px'
  }
};

const EmailVerificationAlert = ({ params, api }) => {
  const [sending, setSending] = useState(false);
  const [showError, setShowError] = useState(false);
  const [sendError, setSendError] = useState('');

  const handleResend = async (e) => {
    e.preventDefault();
    const { email } = params;
    setSending(true);

    const headers = {
      client_id: config.mulesoft.clientId,
      client_secret: config.mulesoft.clientSecret
    };

    try {
      await api.post(
        '/identity/email-verification',
        { email: email.replace(' ', '+') },
        { headers }
      );
    } catch (error) {
      let description;
      if (error.response) {
        description = error.response.data.error;
      } else if (error.request) {
        description =
          'Network error. Please check your connection and try again.';
      } else {
        description =
          'There was an error sending the verification email. Please try again later.';
      }
      setSendError(description);
    }

    setSending(false);
    setShowError(true);
  };

  return params.error && !showError ? (
    <Card style={styles.resendErrorCard}>
      <Typography
        type="subtitle2"
        style={{ color: '#DC2A2A', fontWeight: 'normal', textAlign: 'center' }}
      >
        {params.error_description}
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        {sending ? (
          <div style={{ marginTop: '12px' }}>
            <Spinner color="#DC2A2A" />
          </div>
        ) : (
          <div
            onClick={handleResend}
            onKeyPress={handleResend}
            style={styles.resendErrorLink}
            role="link"
            tabIndex={0}
          >
            <Typography
              type="caption1"
              component="span"
              style={{ color: '#DC2A2A' }}
            >
              Resend verification email
            </Typography>
          </div>
        )}
      </div>
    </Card>
  ) : (
    <Card style={styles.resendSentCard}>
      <Typography
        type="subtitle2"
        style={{ color: '#333333', fontWeight: 'normal', textAlign: 'center' }}
      >
        {sendError === '' ? 'Verification email has been sent.' : sendError}
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        {sending ? (
          <div style={{ marginTop: '12px' }}>
            <Spinner />
          </div>
        ) : (
          <div
            onClick={handleResend}
            onKeyPress={handleResend}
            style={styles.resendSentLink}
            role="link"
            tabIndex={0}
          >
            <Typography
              type="caption1"
              component="span"
              style={{ color: '#333333' }}
            >
              Resend verification email
            </Typography>
          </div>
        )}
      </div>
    </Card>
  );
};

EmailVerificationAlert.propTypes = {
  params: PropTypes.shape({
    error: PropTypes.string,
    // eslint-disable-next-line camelcase
    error_description: PropTypes.string,
    email: PropTypes.string
  }),
  api: customTypes.api
};

export default withApi(EmailVerificationAlert);

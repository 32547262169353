import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Row,
  Col,
  Button,
  PolicyRow,
  Typography
} from 'components/alf-design';
import { formats } from 'utils';

const PolicyCancelled = ({ agent, handleHistoryClick, isDuckCreek }) => {
  return (
    <Card>
      <Typography type="heading3" component="h3">
        Billing
      </Typography>
      <Typography
        type="caption1"
        component="span"
        style={{
          display: 'inline-block',
          color: '#DC2A2A'
        }}
      >
        This policy is cancelled. For billing information, please contact your
        agent.
      </Typography>
      <Row gutter={24} className="billing-content">
        <Col xl={10} xxl={8}>
          <>
            <PolicyRow
              label="Agency Name"
              value={agent.organizationName}
              vertical
            />
            <PolicyRow
              label="Agency Phone Number"
              value={formats.phoneFormat(agent.phone)}
              vertical
            />
          </>
        </Col>
        <Col
          xl={12}
          xxl={8}
          style={{ padding: '0 12px' }}
          className="billing-buttons"
        >
          {isDuckCreek ? (
            <Button
              id="payment-history-button"
              type="secondary"
              fullWidth
              onClick={handleHistoryClick}
              style={{ marginTop: 12 }}
            >
              Payment History
            </Button>
          ) : (
            // this is always disabled ???
            <Button type="primary" fullWidth disabled>
              Payment Portal
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
};

PolicyCancelled.propTypes = {
  agent: PropTypes.object,
  isDuckCreek: PropTypes.bool,
  handleHistoryClick: PropTypes.func
};

export default PolicyCancelled;

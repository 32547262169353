import React from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, Typography } from '../../../../alf-design';
import './Warning.css';

const styles = {
  actions: {
    alignSelf: 'center'
  },
  wrapper: {
    width: 570,
    padding: '10px 10px 20px 10px'
  }
};

const Warning = ({ show, onClose }) => {
  return (
    <Dialog
      id="warning-popup"
      show={show}
      onClose={onClose}
      actionsStyle={styles.actions}
      wrapperStyle={styles.wrapper}
      actions={
        <div className="warning__div">
          <Button
            id="button-close"
            type="primary"
            onClick={onClose}
            className="warning__button"
          >
            Close
          </Button>
        </div>
      }
    >
      <div className="warning__content">
        <Typography
          type="heading3"
          component="h2"
          style={{ marginBottom: 20, textAlign: 'center' }}
        >
          Direct Mail Document Delivery
        </Typography>
        <div className="warning__warning_div">
          <Typography type="subtitle2" className="warning__warning_title">
            Warning!
          </Typography>
          <Typography
            type={'caption1'}
            component="span"
            className="warning__warning_content"
          >
            This will change your billing statement. To continue to receive your
            Electronic Delivery Discount you must be enrolled in paperless
            billing.
          </Typography>
        </div>
        <div className="warning__paragraph">
          <Typography
            type={'caption1'}
            component="span"
            style={{ fontSize: 13 }}
          >
            {`To remove your Electronic Delivery Discount from your billing
            statement and enroll in Direct Mail Delivery of policy and billing
            documents please call our customer service team at `}
            <a
              href="tel:8007482030"
              style={{
                color: '#6648DC',
                textDecoration: 'none',
                fontWeight: 600
              }}
            >
              (800) 748-2030.
            </a>
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};

Warning.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func
};

export default Warning;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MaskedInput from 'react-text-mask';
import { useStorage } from 'utils';

import Icon from '../Icon';
import './Input.css';

const MaskInput = React.forwardRef(
  (
    {
      mask,
      fullWidth,
      success,
      error,
      name,
      placeholder,
      onChange,
      value,
      onFocus,
      onBlur,
      disabled,
      id,
      type
    },
    ref
  ) => {
    const [isMobile] = useStorage('isMobile', false);

    const handleChange = (e) => {
      switch (type) {
        case 'phone':
          if (e.target.value.replace(/\D+/g, '').length <= 3) {
            e.target.value = e.target.value.replace(/\D+/g, '');
          }
          if (
            e.target.value.replace(/\D+/g, '').length > 3 &&
            e.target.value.replace(/\D+/g, '').length < 7
          ) {
            let t = 0;
            e.target.value = e.target.value.replace(/\D+/g, (match) => {
              t += 1;
              return t === 2 ? '' : match;
            });
          }
          e.target.value = e.target.value.trim();

          break;

        default:
          break;
      }
      onChange(e);
    };

    const maskHandle = (e) => {
      switch (type) {
        case 'phone':
          return [
            /[1-9]/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/
          ];

        case 'zip': {
          return [/\d/, /\d/, /\d/, /\d/, /\d/];
        }

        case 'expDate': {
          return [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/];
        }

        default:
          return mask;
      }
    };

    return (
      <div ref={ref}>
        <MaskedInput
          mask={maskHandle}
          className={clsx(
            'alf-input',
            fullWidth && 'fullWidth',
            success && 'success',
            error && 'error',
            isMobile && 'no-focus'
          )}
          name={name}
          placeholder={placeholder}
          onChange={handleChange}
          defaultValue={{ ...value }}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          id={id}
          placeholderChar={'\u2000'}
        />
        {type === 'expDate' && (
          <Icon
            style={{
              marginLeft: '-25px',
              marginTop: '7px',
              position: 'absolute'
            }}
            fontSize={20}
            name="calendar"
            color="#5B564D"
          />
        )}
      </div>
    );
  }
);
MaskInput.propTypes = {
  mask: PropTypes.array,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.oneOf(['phone', 'zip', 'expDate', 'other'])
};

MaskInput.defaultProps = {
  disabled: false,
  fullWidth: true,
  type: 'other'
};

export default MaskInput;

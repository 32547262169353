import { useState, useCallback } from 'react';

const useToggle = (initial, other = !initial) => {
  const [value, setValue] = useState(initial);
  const toggle = useCallback(
    (override) => {
      if (
        override !== undefined &&
        (override === initial || override === other)
      ) {
        setValue(override);
      } else {
        setValue((prev) => (prev === initial ? other : initial));
      }
    },
    [initial, other]
  );
  return [value, toggle];
};
export default useToggle;

import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Button } from '../alf-design';

const ClaimDetailsAssistance = ({ align }) => (
  <div
    style={{
      textAlign: align,
      marginTop: align === 'left' ? '0px' : align === 'center' ? '24px' : '0px'
    }}
  >
    <div
      style={{
        textAlign: align === 'left' ? 'left' : 'center',
        display: 'inline-block'
      }}
    >
      <Typography
        type="caption1"
        style={{ fontWeight: align === 'center' ? 0 : 800 }}
      >
        Need claims assistance?
      </Typography>
      <Button
        style={{
          width: '234px',
          marginTop: '10px',
          fontWeight: 'normal',
          marginLeft: align === 'left' ? '-20px' : 0,
          marginBottom: align === 'center' ? '24px' : 0
        }}
        to="tel:888-256-3378"
        size="lg"
      >
        <b>(888) 256-3378</b>
      </Button>
    </div>
  </div>
);
ClaimDetailsAssistance.propTypes = {
  align: PropTypes.oneOf(['center', 'left', 'right'])
};

export default ClaimDetailsAssistance;

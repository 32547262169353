import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useAuth0, useResponsive } from 'utils';
import './Login.css';

import { Row, Col, Button, Typography, Icon } from '../alf-design';

const Login = ({ history }) => {
  const { screenIsUp } = useResponsive();
  const { loginWithRedirect, loading } = useAuth0();
  const handleLogin = () => {
    loginWithRedirect();
  };
  const handleCreateAccount = () => {
    history.push('/create-account');
  };

  const renderCheckBox = (title) => (
    <li className="list-item">
      <Icon
        name="checkCircle"
        fontSize={16}
        color="#6647DC"
        style={{ marginRight: '12px', width: '16px', height: '16px' }}
        alt=""
      />

      <Typography type="paragraph" component="p" className="icon-title">
        {title}
      </Typography>
    </li>
  );

  return (
    <div id="landingInformational">
      <Row type="flex" justify="center">
        <Button
          id="landing-button-login"
          type="primary"
          disabled={loading}
          onClick={handleLogin}
          className="primary-button"
          link
        >
          Log In
        </Button>
      </Row>
      <Row type="flex" justify="center">
        <Button
          id="landing-button-signup"
          onClick={handleCreateAccount}
          disabled={loading}
          className="second-button"
          link
        >
          Create Account
        </Button>
      </Row>
      {screenIsUp('md') ? (
        <>
          <Typography type="heading3" component="h2" className="subtitle">
            When you’ll get access.
          </Typography>

          <Typography
            type="paragraph"
            component="p"
            style={{ color: '#6647dc' }}
          >
            Access to the new policyholder portal is being rolled out in phases.
            If your policy number begins with H3, H6, or D3, you can access the
            portal
            <b>{` the day after your policy is bound.`}</b>
          </Typography>

          <Typography
            type="heading3"
            component="h2"
            className="second-subtitle"
          >
            Why sign up for online access?
          </Typography>

          <Row type="flex" justify="space-between" className="row">
            <Col>
              <ul className="ul">{renderCheckBox('View policy details')}</ul>
              <ul className="ul">
                {renderCheckBox('Download policy documents')}
              </ul>
              <ul className="ul">
                {renderCheckBox('Access agent contact info')}
              </ul>
            </Col>
            <Col>
              <ul className="ul">{renderCheckBox('Make a payment')}</ul>
              <ul className="ul">
                {renderCheckBox('View billing and payment details')}
              </ul>
              <ul className="ul">
                {renderCheckBox('View claim details - Coming soon!')}
              </ul>
            </Col>
          </Row>
        </>
      ) : (
        <div className="mobile">
          <Typography type="heading3" component="h2" className="subtitle">
            Please Note:
          </Typography>
          <Typography type="paragraph" component="p" className="mobile-p">
            Only policies beginning with H3, H6, or D3 can create an online
            account.
          </Typography>
          <Typography
            type="paragraph"
            component="p"
            className="mobile-second-p"
          >
            Access to the new mySlide online account will be gradually released
            to all Slide policyholders over the next year.
          </Typography>
        </div>
      )}
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object.isRequired
};
export default withRouter(Login);

import flSlide from '../assets/img/policy-help/FL.png';

export default {
  US: [
    { code: '1', codeValue: 'AL', name: 'Alabama' },
    { code: '2', codeValue: 'AZ', name: 'Arizona' },
    { code: '3', codeValue: 'AR', name: 'Arkansas' },
    { code: '4', codeValue: 'CA', name: 'California' },
    { code: '5', codeValue: 'CO', name: 'Colorado' },
    {
      code: '6',
      codeValue: 'CT',
      name: 'Connecticut',
      zipRanges: [
        { zipMin: '06001', zipMax: '06389' },
        { zipMin: '06401', zipMax: '06928' }
      ]
    },

    { code: '7', codeValue: 'DE', name: 'Delaware' },
    { code: '8', codeValue: 'DC', name: 'District of Columbia' },
    {
      code: '9',
      codeValue: 'FL',
      src: [flSlide],
      name: 'Florida',
      zipRanges: [{ zipMin: '32003', zipMax: '34997' }]
    },
    {
      code: '10',
      codeValue: 'GA',
      name: 'Georgia',
      zipRanges: [
        { zipMin: '30001', zipMax: '31999' },
        { zipMin: '39813', zipMax: '39897' },
        { zipMin: '39901', zipMax: '39901' }
      ]
    },

    { code: '11', codeValue: 'ID', name: 'Idaho' },
    { code: '12', codeValue: 'IL', name: 'Illinois' },
    { code: '13', codeValue: 'IN', name: 'Indiana' },
    { code: '14', codeValue: 'IA', name: 'Iowa' },
    { code: '15', codeValue: 'KS', name: 'Kansas' },
    { code: '16', codeValue: 'KY', name: 'Kentucky' },
    {
      code: '17',
      codeValue: 'LA',
      name: 'Louisiana',
      zipRanges: [{ zipMin: '70001', zipMax: '71497' }]
    },

    {
      code: '20',
      codeValue: 'MA',
      name: 'Massachusetts',
      zipRanges: [
        { zipMin: '01001', zipMax: '02791' },
        { zipMin: '05501', zipMax: '05544' }
      ]
    },

    { code: '21', codeValue: 'MI', name: 'Michigan' },
    { code: '22', codeValue: 'MN', name: 'Minnesota' },
    { code: '23', codeValue: 'MS', name: 'Mississippi' },
    { code: '24', codeValue: 'MO', name: 'Missouri' },
    { code: '25', codeValue: 'MT', name: 'Montana' },
    { code: '26', codeValue: 'NE', name: 'Nebraska' },
    { code: '27', codeValue: 'NV', name: 'Nevada' },
    { code: '28', codeValue: 'NH', name: 'New Hampshire' },
    {
      code: '29',
      codeValue: 'NJ',
      name: 'New Jersey',
      zipRanges: [{ zipMin: '07001', zipMax: '08989' }]
    },
    { code: '30', codeValue: 'NM', name: 'New Mexico' },
    {
      code: '31',
      codeValue: 'NY',
      name: 'New York',
      zipRanges: [
        { zipMin: '00501', zipMax: '00501' },
        { zipMin: '00544', zipMax: '00544' },
        { zipMin: '06390', zipMax: '06390' },
        { zipMin: '10001', zipMax: '14975' }
      ]
    },
    {
      code: '32',
      codeValue: 'NC',
      name: 'North Carolina',
      zipRanges: [{ zipMin: '27006', zipMax: '28909' }]
    },

    { code: '33', codeValue: 'ND', name: 'North Dakota' },
    { code: '34', codeValue: 'OH', name: 'Ohio' },
    { code: '35', codeValue: 'OK', name: 'Oklahoma' },
    { code: '36', codeValue: 'OR', name: 'Oregon' },
    { code: '37', codeValue: 'PA', name: 'Pennsylvania' },
    {
      code: '38',
      codeValue: 'RI',
      name: 'Rhode Island',
      zipRanges: [{ zipMin: '02801', zipMax: '02940' }]
    },
    {
      code: '39',
      codeValue: 'SC',
      name: 'South Carolina',
      zipRanges: [{ zipMin: '29001', zipMax: '29948' }]
    },

    { code: '40', codeValue: 'SD', name: 'South Dakota' },
    { code: '41', codeValue: 'TN', name: 'Tennessee' },
    {
      code: '42',
      codeValue: 'TX',
      name: 'Texas',
      zipRanges: [
        { zipMin: '73301', zipMax: '73301' },
        { zipMin: '73344', zipMax: '73344' },
        { zipMin: '73960', zipMax: '73960' },
        { zipMin: '75001', zipMax: '75501' },
        { zipMin: '75503', zipMax: '79999' },
        { zipMin: '88510', zipMax: '88590' },
        { zipMin: '88595', zipMax: '88595' }
      ]
    },
    { code: '43', codeValue: 'UT', name: 'Utah' },
    { code: '44', codeValue: 'VT', name: 'Vermont' },
    { code: '45', codeValue: 'VA', name: 'Virginia' },
    { code: '46', codeValue: 'WA', name: 'Washington' },
    { code: '47', codeValue: 'WV', name: 'West Virginia' },
    { code: '48', codeValue: 'WI', name: 'Wisconsin' },
    { code: '49', codeValue: 'WY', name: 'Wyoming' },
    {
      code: '52',
      codeValue: 'HI',
      name: 'Hawaii',
      zipRanges: [{ zipMin: '96701', zipMax: '96898' }]
    },
    { code: '54', codeValue: 'AK', name: 'Alaska' },
    { code: '55', codeValue: 'AS', name: 'American Samoa' },
    { code: '56', codeValue: 'AA', name: 'Armed Forces (AA)' },
    { code: '57', codeValue: 'AE', name: 'Armed Forces (AE)' },
    { code: '58', codeValue: 'AP', name: 'Armed Forces (AP)' },
    { code: '59', codeValue: 'GU', name: 'Guam' },
    { code: '60', codeValue: 'MH', name: 'Marshall Islands' },
    { code: '61', codeValue: 'FM', name: 'Micronesia, Federated States Of' },
    { code: '62', codeValue: 'MP', name: 'Northern Mariana Islands' },
    { code: '63', codeValue: 'PW', name: 'Palau' },
    { code: '64', codeValue: 'PR', name: 'Puerto Rico' },
    { code: '65', codeValue: 'VI', name: 'Virgin Islands, U.S.' }
  ],
  CA: [
    { code: '1', codeValue: 'AB', name: 'Alberta' },
    { code: '2', codeValue: 'BC', name: 'British Columbia' },
    { code: '3', codeValue: 'MB', name: 'Manitoba' },
    { code: '4', codeValue: 'NB', name: 'New Brunswick' },
    { code: '5', codeValue: 'NL', name: 'Newfoundland and Labrador' },
    { code: '6', codeValue: 'NT', name: 'Northwest Territories' },
    { code: '7', codeValue: 'NS', name: 'Nova Scotia' },
    { code: '9', codeValue: 'NU', name: 'Nunavut' },
    { code: '10', codeValue: 'ON', name: 'Ontario' },
    { code: '11', codeValue: 'PE', name: 'Prince Edward Island' },
    { code: '12', codeValue: 'QC', name: 'Quebec' },
    { code: '13', codeValue: 'SK', name: 'Saskatchewan' },
    { code: '14', codeValue: 'YT', name: 'Yukon Territory' }
  ]
};

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './Pagination.css';

import { Button } from '..';

const Pagination = ({ current = 1, pageSize = 10, total, onChange }) => {
  const handlePage = (page) => {
    onChange(page);
  };

  const handlePrev = () => {
    onChange(current - 1);
  };

  const handleNext = () => {
    onChange(current + 1);
  };

  let pageButtons = [];
  let pagesAmount = Math.ceil(total / pageSize);

  for (let page = 1; page <= pagesAmount; page++) {
    pageButtons.push(
      <li className={clsx(current === page && 'current')}>
        <Button onClick={() => handlePage(page)} size="xs">
          {page}
        </Button>
      </li>
    );
  }

  return (
    <div className="alf-pagination">
      <ul>
        <li style={{ marginRight: 5 }}>
          <Button onClick={handlePrev} disabled={current === 1} size="xs">
            {`<`}
          </Button>
        </li>
        {pageButtons}
        <li style={{ marginLeft: 5 }}>
          <Button
            onClick={handleNext}
            disabled={current === pagesAmount}
            size="xs"
          >
            {`>`}
          </Button>
        </li>
      </ul>
    </div>
  );
};

Pagination.propTypes = {
  current: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  onChange: PropTypes.func
};

export default Pagination;

import React from 'react';
import './Switch.css';

const Switch = ({
  handleChange,
  values,
  name,
  id,
  disabled = false,
  showYesNo = true
}) => {
  const switchChange = (e) => {
    handleChange({
      ...e,
      target: { ...e.target, name: name, value: !values }
    });
  };

  return (
    <div className="react-switch-div">
      {showYesNo && <span className="react-switch-span">No</span>}
      <div className="mt-1">
        <input
          className="react-switch-checkbox"
          id={id ?? name}
          type="checkbox"
          onChange={switchChange}
          aria-checked={values}
          name={name}
          value={values}
          disabled={disabled}
          checked={values}
        />
        <label
          className={'react-switch-label'}
          aria-label={name}
          htmlFor={id ?? name}
        >
          <span className={'react-switch-button'} />
        </label>
      </div>
      {showYesNo && <span className="react-switch-span">Yes</span>}
    </div>
  );
};

export default Switch;

/* eslint-disable no-console */
import React, { useLayoutEffect } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import queryString from 'query-string';
import { logo } from 'assets/img';
import {
  Typography,
  Col,
  Row,
  Button,
  Link,
  Icon
} from 'components/alf-design';
import { Background, Footer } from 'components/layout';
import { useStorage } from 'utils';

import 'components/alf-design/AppBar/AppBar.css';

const styles = {
  layout: {
    margin: 52,
    marginTop: 33,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column'
  },
  wrapper: {
    maxWidth: 800,
    margin: '0 auto',
    textAlign: 'center',
    flexDirection: 'column'
  },
  oh: {
    textAlign: 'center',
    fontSize: 'min(59rem, calc(59px * 1.5)',
    marginBottom: 30
  },
  title: {
    marginTop: 32,
    marginBottom: 16
  },
  message: {
    textAlign: 'center'
  },
  button: {
    marginTop: 32
  }
};

const Fallback = () => {
  const params = queryString.parse(window.location.search);
  const [isMobile, setIsMobile] = useStorage('isMobile', false);
  const message = isMobile
    ? ' Otherwise, please go back and try again.'
    : ' Otherwise, please return to the dashboard and try again.';
  useLayoutEffect(() => {
    if (params.view === 'mobile') {
      setIsMobile(true);
    }
    if (params.view === 'web') {
      setIsMobile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Background isMobile={isMobile}>
      <Link href="#error-main-content" className="alf-skip-link">
        Skip to Content
      </Link>
      {!isMobile && (
        <header className="alf-app-bar" role="banner">
          <div className="left">
            <a href="/">
              <img
                src={logo}
                alt="Logo"
                className="logo"
                style={{ width: 182 }}
              />
            </a>
          </div>
        </header>
      )}
      <main id="error-main-content" role="main" style={styles.layout}>
        <Row gutter={24} style={styles.wrapper}>
          <Col md={12}>
            <Icon
              name="timesCircle"
              fontSize={80}
              color="#6648DC"
              style={styles.title}
            />
            <Typography type="heading1" component="h1" style={styles.oh}>
              uh - oh!
            </Typography>
          </Col>
          <Col md={12}>
            <div>
              <Typography type="heading2" component="h2" style={styles.title}>
                Something went wrong.
              </Typography>
              <Typography type="paragraph">
                If you need immediate assistance, please contact Customer
                Service at{' '}
                <a
                  href="tel:8007482030"
                  style={{ color: '#6648DC', textDecoration: 'none' }}
                >
                  (800) 748-2030
                </a>
                .
              </Typography>
              <Typography type="paragraph">{message}</Typography>
            </div>
            {!isMobile && (
              <Button type="primary" style={styles.button} link to="/">
                Back to Dashboard
              </Button>
            )}
          </Col>
        </Row>
      </main>
      {!isMobile && <Footer />}
    </Background>
  );
};

const ErrorBoundary = (props) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={Fallback}
      onError={(error) => console.log(error)}
    >
      {props.children}
    </ReactErrorBoundary>
  );
};
export default ErrorBoundary;

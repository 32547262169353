import React from 'react';
import PropTypes from 'prop-types';
import './ProgressStep.css';
import clsx from 'clsx';

import StepperContext from './StepperContext';

const Stepper = ({ orientation, children }) => (
  <StepperContext.Provider value={{ orientation }}>
    <ul className={clsx(' alf-stepper', orientation)}>{children}</ul>
  </StepperContext.Provider>
);

Stepper.propTypes = {
  orientation: PropTypes.string,
  children: PropTypes.any
};

Stepper.defaultProps = {
  orientation: 'horizontal'
};

export default Stepper;

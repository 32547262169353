import React from 'react';
import PropTypes from 'prop-types';
import './MakePayment.css';
import { useResponsive } from 'utils';

import { publicFooterLogo } from '../../assets/img';
import { Button, Dialog, Typography } from '../alf-design';

const MakePayment = ({ show, onClose, onPayment, selectedPolicy }) => {
  const { currentDueAmount, pastDueAmount } = selectedPolicy.billing;
  const noPaymentDue = currentDueAmount === 0 && pastDueAmount === null;
  const { width } = useResponsive();
  const smallScreen = width < 768;
  const styles = {
    actions: {
      alignSelf: 'center',
      marginTop: 25,
      marginBottom: smallScreen ? 20 : 100
    },
    wrapper: {
      width: 575,
      minHeight: 475
    }
  };

  return (
    <Dialog
      id="make-payment-popup"
      show={show}
      onClose={onClose}
      actionsStyle={styles.actions}
      wrapperStyle={styles.wrapper}
      actions={
        <div>
          <Button
            id="button-go-back"
            onClick={onClose}
            className="make_payment__button"
          >
            Go Back
          </Button>
          {!noPaymentDue && (
            <Button
              id="button-ok"
              type="primary"
              onClick={onPayment}
              className="make_payment__button"
            >
              Ok
            </Button>
          )}
        </div>
      }
    >
      <div className="make_payment__child">
        <img
          src={publicFooterLogo}
          width="225"
          alt="Slide Insurance logo"
          id="slide-insurance-logo"
          className="make_payment__logo"
        />
        {noPaymentDue ? (
          <Typography type="paragraph" className="make_payment__text">
            No payment is due. If you would like to make an advance payment,
            please call Customer Service at (800) 748-2030.
          </Typography>
        ) : (
          <>
            <Typography type="paragraph" className="make_payment__text">
              I understand and agree that by clicking "OK," I am leaving the
              Slide website to make a payment with One Inc., a third-party
              payment processor.
            </Typography>
            <br />
            <Typography type="paragraph" className="make_payment__text">
              A 2.99% fee is incurred and retained by One Inc., when paying by
              credit card. You can avoid this fee by selecting E-Check or
              choosing to pay by check.
            </Typography>
          </>
        )}
      </div>
    </Dialog>
  );
};

MakePayment.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func
};

export default MakePayment;

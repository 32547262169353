import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const PasswordPopup = ({ width = 256, space = 16, children, text }) => {
  const [visible, setVisible] = useState(false);
  const [style, setStyle] = useState({});
  const [el, setEl] = useState(undefined);
  const handleScroll = () => setVisible(false);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const showTooltip = () => {
    const newStyle = { width };
    const dimensions = el.getBoundingClientRect();

    newStyle.left = dimensions.left + dimensions.width + space;
    newStyle.left = Math.max(space, newStyle.left);
    newStyle.left = Math.min(
      newStyle.left,
      document.body.clientWidth - width - space
    );
    newStyle.top = dimensions.top - 40;
    setVisible(true);
    delete newStyle.width;
    setStyle(newStyle);
  };
  const hideTooltip = () => {
    setVisible(false);
  };
  const tooltip = (
    <div className="alf-passwordcheck tooltip-container" style={style}>
      <div className="tooltip-body">
        {text}
        <span className="arrow" />
      </div>
    </div>
  );
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <span
      role="tooltip"
      onKeyDown={showTooltip}
      onFocus={showTooltip}
      onChange={showTooltip}
      onClick={showTooltip}
      onBlur={hideTooltip}
      ref={(elem) => setEl(elem)}
    >
      {children}

      {visible && tooltip}
    </span>
  );
};
PasswordPopup.propTypes = {
  width: PropTypes.number,
  space: PropTypes.number,
  text: PropTypes.object,
  children: PropTypes.any
};
PasswordPopup.defaultProps = {
  width: 256,
  space: 16
};
export default PasswordPopup;

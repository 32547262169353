import React from 'react';

import { Typography, Card } from '../alf-design';

const styles = {
  resendErrorLink: {
    marginTop: '12px',
    cursor: 'pointer',
    textDecoration: 'underline #DC2A2A',
    textAlign: 'center',
    maxWidth: 'max-content'
  },
  resendSentLink: {
    marginTop: '12px',
    cursor: 'pointer',
    textDecoration: 'underline #333333',
    textAlign: 'center',
    maxWidth: 'max-content'
  },
  resendErrorCard: {
    margin: '24px',
    marginTop: '24px',
    backgroundColor: '#FF00001A',
    boxShadow: 'initial',
    borderRadius: '0px'
  },
  resendSentCard: {
    margin: '24px',
    marginTop: '24px',
    backgroundColor: '#6648DC',
    boxShadow: 'initial',
    borderRadius: '0px'
  }
};

const BlockedAccountAlert = () => {
  return (
    <Card style={styles.resendErrorCard}>
      <Typography
        type="subtitle2"
        style={{ color: '#DC2A2A', fontWeight: 'normal', textAlign: 'center' }}
      >
        Your account has been blocked. If you haven't received an email to
        unblock, please contact support.
      </Typography>
    </Card>
  );
};

export default BlockedAccountAlert;

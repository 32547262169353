/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { useResponsive, useStorage, useFlags } from 'utils';

import { Row, Col, Typography } from '../alf-design';
import {
  Login,
  RestartLogin,
  CreateAccount,
  EmailVerification,
  AccountUnblocked
} from '../login';
import PanelRight from './PanelRight';
import BlockedAccountAlert from '../login/BlockedAccountAlert';
import EmailVerificationAlert from '../login/EmailVerificationAlert';
import { publicSlideLogo } from '../../assets/img';
import Background from './Background';
import Footer from './Footer';
import './PublicLayout.css';

const styles = {
  layout: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  panelLeft: {
    paddingRight: '10%'
  },
  panelLeftMobile: {
    paddingRight: '5%'
  },
  logo: {
    width: 280,
    marginTop: 100
  },
  panelRow: {
    marginLeft: '2%',
    marginRight: '2%',
    marginBottom: '3%'
  },
  mobilePanelRow: {
    marginLeft: '5%',
    marginRight: '5%'
  }
};

const PublicLayout = ({ location, history, loading }) => {
  const params = queryString.parse(location.search);
  const { screenIsUp } = useResponsive();
  const [ready, setReady] = useState(null);
  const [isMobile, setIsMobile] = useStorage('isMobile', false);

  const { showComingSoon } = useFlags();

  const { pathname } = location;

  useEffect(() => {
    if (pathname !== '/create-account') {
      document.title = 'Online Policy Access for Slide Insurance Policyholders';
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute(
          'content',
          'Policy access is designed with you in mind. View your policy details, make payments, access your agent info, and more!'
        );
      }
    }
  }, [location]);

  useLayoutEffect(() => {
    if (params.error === 'sign_up_requested') {
      history.push('/create-account');
    }

    if (params.view === 'mobile') {
      setIsMobile(true);
    }
    if (params.view === 'web') {
      setIsMobile(false);
    }
    setReady(true);
  }, []);

  return (
    ready && (
      <Background isMobile={isMobile}>
        <main id="public-main-content" role="main">
          <div style={styles.layout}>
            {showComingSoon ? (
              <>
                <Row>
                  <img src={publicSlideLogo} alt="Logo" style={styles.logo} />
                </Row>
                <Row>
                  <Typography
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      height: 500
                    }}
                    type="welcome"
                    component="h1"
                  >
                    Coming Soon
                  </Typography>
                </Row>
              </>
            ) : (
              <Switch>
                <Route path="/create-account" component={CreateAccount} />
                <Route path="/account-unblocked" component={AccountUnblocked} />
                <Route
                  path="/email-verification"
                  component={EmailVerification}
                />
                <Route exact path="/login" component={RestartLogin} />
                {!loading && (
                  <>
                    <Row>
                      <img
                        src={publicSlideLogo}
                        alt="Logo"
                        style={styles.logo}
                      />
                    </Row>
                    <Row style={{ marginTop: 33, marginBottom: 33 }}>
                      {params.error === 'unverified_email' && (
                        <EmailVerificationAlert params={params} />
                      )}
                      {params.error === 'unauthorized' &&
                        params.error_description === 'user is blocked' && (
                          <BlockedAccountAlert />
                        )}
                    </Row>
                    <Row
                      style={
                        screenIsUp('lg')
                          ? styles.panelRow
                          : styles.mobilePanelRow
                      }
                    >
                      <Col xl={12} className="panelLeft">
                        <Switch>
                          <Route exact path="/" component={Login} />
                          <Route render={() => <Redirect to="/" />} />
                        </Switch>
                      </Col>
                      {screenIsUp('md') && (
                        <>
                          <hr className="hr"></hr>
                          <Col xl={12} className="panelRight">
                            <PanelRight />
                          </Col>
                        </>
                      )}
                    </Row>
                  </>
                )}
              </Switch>
            )}
          </div>
        </main>
        {!isMobile && <Footer />}
      </Background>
    )
  );
};

PublicLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  loading: PropTypes.bool
};

export default withRouter(PublicLayout);

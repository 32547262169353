import React from 'react';
import PropTypes from 'prop-types';
import { customTypes, formats, useFlags } from 'utils';
import { Typography, Link, Spinner } from 'components/alf-design';

import MortgageePaid from './MortgageePaid';
import InsuredPaid from './InsuredPaid';
import PaperlessDiscount from './PaperlessDiscount';

const styles = {
  circularProgress: {
    display: 'flex',
    justifyContent: 'center'
  }
};

const BillingContentDC = ({
  billing,
  insuredEmail,
  agent,
  loading,
  policyNumber,
  pendingEndorsement,
  getPolicy,
  ...insuredProps
}) => {
  const {
    showCancellationData,
    showMakePayments,
    disableMakePayments
  } = useFlags();

  if (loading) {
    return (
      <div style={styles.circularProgress}>
        {loading && <Spinner id="dashboard-spinner" size={80} />}
      </div>
    );
  }

  if (!billing || billing.error) {
    const errorMessage = billing
      ? billing.error
      : 'There was an error fetching your billing information. Please try again later.';

    return (
      <>
        <Typography
          type="caption1"
          component="span"
          style={{
            display: 'inline-block',
            color: '#DC2A2A',
            marginTop: 10,
            marginBottom: 20
          }}
        >
          {errorMessage}
        </Typography>
        <Typography type="caption1" component="p" style={{ marginBottom: -10 }}>
          For immediate assistance, please contact your agent.
        </Typography>
        <Typography type="paragraph" component="p" style={{ marginTop: 26 }}>
          <b>{agent.organizationName}</b>
        </Typography>
        <Link type="primary" href={`tel:${agent.phone}`}>
          {formats.phoneFormat(agent.phone)}
        </Link>
      </>
    );
  }

  if (billing.isMortgagee) {
    return (
      <>
        <MortgageePaid
          billing={billing}
          showCancellationData={showCancellationData}
          policyStatus={insuredProps.statusCode}
        />
        <hr style={{ margin: '25px 15px 25px 15px' }} />
        <PaperlessDiscount
          paperless={billing.isPaperless}
          preferredEmail={insuredEmail}
          policyNumber={policyNumber}
          pendingEndorsement={pendingEndorsement}
          getPolicy={getPolicy}
        />
      </>
    );
  }

  return (
    <>
      <InsuredPaid
        agent={agent}
        billing={billing}
        showCancellationData={showCancellationData}
        showMakePayments={showMakePayments}
        disableMakePayments={disableMakePayments}
        {...insuredProps}
      />
      <hr style={{ margin: '25px 15px 25px 15px' }} />
      <PaperlessDiscount
        paperless={billing.isPaperless}
        preferredEmail={insuredEmail}
        policyNumber={policyNumber}
        pendingEndorsement={pendingEndorsement}
        getPolicy={getPolicy}
      />
    </>
  );
};

BillingContentDC.propTypes = {
  billing: customTypes.billing,
  insuredEmail: PropTypes.string,
  agent: customTypes.agent,
  policyNumber: PropTypes.string,
  pendingEndorsement: PropTypes.bool,
  getPolicy: PropTypes.func
};

export default BillingContentDC;

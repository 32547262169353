import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import PasswordPopup from './PasswordPopup';
import Typography from '../Typography';
import ProgressBar from './ProgressBar';
import './PasswordCheck.css';

const lengthRule = {
  rule: /^.{8,64}$/,
  text: 'Password must be 8-64 characters'
};

const checkLength = (password) => lengthRule.rule.test(password);

const validatePassword = (password = '') => {
  const checklist = [];
  const rules = [
    lengthRule,
    {
      rule: /[A-Z]{1}/,
      text: 'Must include at least 3 of: uppercase letters.'
    },
    { rule: /[a-z]{1}/, text: 'Must include at least 3 of: lowercase letters' },
    { rule: /[0-9]{1}/, text: 'Must include at least 3 of: numbers' },
    {
      rule: /[^0-9a-zA-Z]{1}/,
      text: 'Must include at least 3 of: symbols'
    }
  ];
  rules.forEach((obj) => {
    checklist.push({ valid: obj.rule.test(password), text: obj.text });
  });
  return checklist;
};

const getProgressColor = (password, progress) => {
  if (progress < 40 || !checkLength(password)) {
    return 'bar-very-weak';
  }
  if (progress < 80) {
    return 'bar-weak';
  }
  return 'bar-strong';
};

const getProgress = (password, valids, checklist) => {
  if (password.length === 0) {
    return 0;
  }

  if (password.length > 64) {
    return Number.MAX_SAFE_INTEGER;
  }

  return Math.max((valids.length / checklist.length) * 100, 10);
};

const getStrength = (password, progress) => {
  if (progress < 40 || password.length < 8) {
    return 'Very Weak';
  }
  if (progress < 80) {
    return 'Weak';
  }
  if (progress > 100) {
    return 'Too Long';
  }

  return 'Strong';
};

const isPasswordValid = (password, progress) =>
  progress >= 80 && progress <= 100 && checkLength(password);

const getContent = (password, isPopover) => {
  const checklist = validatePassword(password);
  const valids = checklist.filter((obj) => obj.valid);
  const progress = getProgress(password, valids, checklist);

  const wrapperStyle = {
    marginTop: isPopover ? 0 : 20,
    display: password || isPopover ? 'inherit' : 'none'
  };

  return (
    <div style={wrapperStyle}>
      <Typography className="password-heading">
        <b>Password Strength:</b> {getStrength(password, progress)}
      </Typography>
      <ProgressBar
        className={getProgressColor(password, progress)}
        value={progress}
      />
      <Typography type="caption1" className="password-message">
        Password must be 8-64 characters and must include at least 3 of:
        uppercase letters, lowercase letters, numbers, or symbols.
      </Typography>
    </div>
  );
};

const PasswordCheck = ({ setPasswordValid, isPopover, password, children }) => {
  useEffect(() => {
    const updatePasswordValidity = () => {
      const checklist = validatePassword(password);
      const valids = checklist.filter((obj) => obj.valid);
      const progress = getProgress(password, valids, checklist);
      const isValid = isPasswordValid(password, progress);
      setPasswordValid(isValid);
    };

    updatePasswordValidity();
  }, [password, setPasswordValid]);

  return isPopover ? (
    <div className="alf-passwordcheck">
      <PasswordPopup text={getContent(password, isPopover)}>
        {children}
      </PasswordPopup>
    </div>
  ) : (
    <div className="alf-passwordcheck">
      {children}
      {getContent(password)}
    </div>
  );
};

PasswordCheck.propTypes = {
  password: PropTypes.string,
  setPasswordValid: PropTypes.func,
  isPopover: PropTypes.bool,
  children: PropTypes.any
};

export default PasswordCheck;

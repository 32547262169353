import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './Label.css';

function Label({ color, text }) {
  return (
    <span
      className={clsx('label', color && `color-${color}`)}
      text={text}
      style={{
        height: 'min-content'
      }}
    >
      {text}
    </span>
  );
}

Label.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string
};

Label.defaultProps = {
  color: 'grey'
};

export default Label;

import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useAuth0, PolicyProvider } from 'utils';
import { datadogRum } from '@datadog/browser-rum';

import config from './../config';
import { Layout, PublicLayout } from './layout';

datadogRum.init({
  applicationId: config.datadog.id,
  clientToken: config.datadog.secret,
  site: 'us5.datadoghq.com',
  service: 'myslide',
  env: config.datadog.env,
  //  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100, // if not included, the default is 100
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true
});

const App = () => {
  const { isAuthenticated, loading, user } = useAuth0();
  // enrich the RUM events
  datadogRum.setGlobalContextProperty('user', user);
  if (isAuthenticated && user) {
    window.DD_RUM.startSessionReplayRecording();
  }

  return (
    <Router>
      <Route
        render={() =>
          isAuthenticated && user ? (
            <PolicyProvider>
              <Layout />
            </PolicyProvider>
          ) : (
            <PublicLayout loading={loading} />
          )
        }
      />
    </Router>
  );
};

export default App;

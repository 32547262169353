import React from 'react';
import PropTypes from 'prop-types';
import { formats } from 'utils';

import { Row, Step, Stepper, PolicyRow, Typography, Link } from '../alf-design';

const ClaimStatusTracker = ({
  claim: {
    createdDate,
    closeDate,
    totalLossAmountPaid,
    stage,
    adjusterEmail,
    adjusterName,
    adjusterPhone
  },
  orientation
}) => {
  const closedWithoutPayment = stage.toUpperCase() === 'CLOSED W/O PAYMENT';
  const outcomeComplete =
    stage.toUpperCase() === 'CLOSED W/ PAYMENT' || closedWithoutPayment;
  const adjusterAssigned =
    stage.toUpperCase() === 'ASSIGNED' || outcomeComplete;

  return (
    <>
      <Typography type="heading3" component="h3">
        Claim Tracker
      </Typography>
      <Stepper orientation={orientation}>
        <Step active title="Claim Reported" statusSeparator={adjusterAssigned}>
          {createdDate && (
            <Typography type="caption1" component="span">
              {formats.dateFormat(createdDate)}
            </Typography>
          )}
        </Step>
        <Step
          active={adjusterAssigned}
          title="Adjuster Assigned"
          statusSeparator={outcomeComplete}
          hiddenContent={!adjusterAssigned}
        >
          <Row>
            <Typography type="caption1" component="span">
              {adjusterName}
            </Typography>
          </Row>
          <Row>
            {adjusterPhone && (
              <Link
                type="primary"
                href={`tel:${formats.phoneFormat(adjusterPhone)}`}
              >
                {formats.phoneFormat(adjusterPhone)}
              </Link>
            )}
          </Row>
          <Row>
            <Link type="primary" href={`mailto:${adjusterEmail}`}>
              {adjusterEmail}
            </Link>
          </Row>
        </Step>
        <Step
          active={outcomeComplete}
          title="Claim Closed"
          lastStep
          hiddenContent={!outcomeComplete && totalLossAmountPaid === '0'}
        >
          <Row>
            {closedWithoutPayment ? (
              <Typography type="caption1" component="span">
                <b>Closed Without Payment</b>
              </Typography>
            ) : (
              <PolicyRow
                valueStyle={{ whiteSpace: 'nowrap' }}
                label="Total Loss Amount Paid"
                value={formats.moneyFormat(totalLossAmountPaid)}
              />
            )}
          </Row>
          {closeDate && (
            <Row>
              <PolicyRow
                valueStyle={{ whiteSpace: 'nowrap' }}
                label="Date Closed"
                value={formats.dateFormat(closeDate)}
              />
            </Row>
          )}
        </Step>
      </Stepper>
    </>
  );
};

ClaimStatusTracker.propTypes = {
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  claim: PropTypes.object.isRequired
};

ClaimStatusTracker.defaultProps = {
  orientation: 'horizontal'
};

export default ClaimStatusTracker;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStorage } from 'utils';
import './Input.css';

const TYPES = ['text', 'email', 'password', 'number', 'date', 'time', 'month'];

const Input = React.forwardRef(
  (
    {
      multiline,
      fullWidth,
      success,
      error,
      type,
      name,
      placeholder,
      id,
      rows,
      onChange,
      onClick,
      onFocus,
      onBlur,
      value,
      maxLength,
      max,
      min,
      disabled
    },
    ref
  ) => {
    const [isMobile] = useStorage('isMobile', false);

    return !multiline ? (
      <input
        className={clsx(
          'alf-input',
          fullWidth && 'fullWidth',
          success && 'success',
          error && 'error',
          isMobile && 'no-focus'
        )}
        type={type}
        name={name}
        placeholder={placeholder}
        id={id}
        onChange={onChange}
        value={value}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={maxLength}
        disabled={disabled}
        max={max}
        min={min}
        ref={ref}
      />
    ) : (
      <textarea
        className={clsx(
          'alf-textarea',
          fullWidth && 'fullWidth',
          success && 'success',
          error && 'error',
          isMobile && 'no-focus'
        )}
        rows={rows}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={maxLength}
        disabled={disabled}
        ref={ref}
      />
    );
  }
);

Input.propTypes = {
  rows: PropTypes.string,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.oneOf(TYPES),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  onClick: PropTypes.func,
  max: PropTypes.string,
  min: PropTypes.string
};

Input.defaultProps = {
  value: '',
  disabled: false,
  type: 'text',
  fullWidth: true,
  multiline: false
};

export default Input;

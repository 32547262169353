/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { states } from 'utils';

import { Typography, Button, Icon, Dialog } from './alf-design';

const styles = {
  content: {
    marginTop: '24px',
    borderBottom: '1px solid #707070'
  },
  body: {
    margin: '0px 16px'
  },
  policyTitle: {
    fontSize: 'min(18rem, calc(18px * 1.5))',
    marginTop: '16px'
  },
  policyImage: {
    height: 'auto',
    width: '100%',
    marginTop: 15
  },
  importantText: {
    fontWeight: 600,
    color: '#ef2e10'
  },
  buttonHolder: {
    order: 3,
    display: 'flex',
    width: '24px',
    justifyContent: 'center',
    position: 'relative'
  },
  button: {
    color: '#6648DC'
  }
};

const Guidance = ({ zip, style, setDisabledGuidance, disabledGuidance }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imgs, setImgs] = useState([]);
  const [havePicture, setHavePicture] = useState(false);

  const getStateByZip = useCallback(() => {
    if (zip && zip.trim().length === 5) {
      let res;
      states.US.forEach((state) => {
        let zipRange;
        if (state.zipRanges) {
          zipRange = state.zipRanges.find(
            (limits) =>
              zip >= parseInt(limits.zipMin, 10) &&
              zip <= parseInt(limits.zipMax, 10)
          );
        }
        if (zipRange) {
          res = state;
        }
      });

      if (res) {
        setDisabledGuidance(false);

        if (res.src) {
          setImgs(res.src);
          setHavePicture(true);
        } else {
          setHavePicture(false);
          setImgs([]);
        }
      } else {
        setDisabledGuidance(true);
      }
    } else {
      setDisabledGuidance(true);
    }
  }, [zip, setDisabledGuidance]);

  const handleClickOpen = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    getStateByZip();
  }, [getStateByZip, zip]);

  return (
    <>
      <Button
        ariaLabel="policy number help"
        style={{
          cursor: disabledGuidance && 'not-allowed',
          margin: '0px 8px',
          display: 'inline-flex',
          verticalAlign: 'sub'
        }}
        htmlType="button"
        onClick={!disabledGuidance ? handleClickOpen : () => {}}
        icon={
          <Icon
            name="questionCircle"
            fontSize={20}
            color="#6648DC"
            disabled={disabledGuidance}
          />
        }
      />
      <Dialog
        show={isOpen}
        onClose={handleClose}
        wrapperStyle={{ paddingBottom: '0px' }}
        heading={
          havePicture && (
            <div style={{ margin: '0px 16px' }}>
              <Typography
                type="heading3"
                component="h2"
                style={{ fontSize: 'min(18rem, calc(18px * 1.5))' }}
              >
                Policy Number Location
              </Typography>
              <Typography
                type="subtitle"
                component="h3"
                style={{
                  fontSize: 'min(16rem, calc(16px * 1.5))',
                  fontWeight: 'normal'
                }}
              >
                Your policy number can be found on your declaration page.
              </Typography>
            </div>
          )
        }
      >
        <div style={styles.body}>
          {!havePicture && (
            <Typography
              type="subtitle"
              component="h3"
              style={{
                fontSize: 'min(16rem, calc(16px * 1.5))',
                marginBottom: '32px',
                maxWidth: '500px',
                textAlign: 'center'
              }}
            >
              The zip code you have entered is not in one of our insured states.
              Please enter the zip code for the insured property.
            </Typography>
          )}

          {imgs &&
            imgs.map((img, index) => (
              <div
                key={index}
                style={{
                  margin: '32px 0px'
                }}
              >
                <img src={img} alt="Policy Number" style={styles.policyImage} />
              </div>
            ))}
        </div>
      </Dialog>
    </>
  );
};

Guidance.propTypes = {
  zip: PropTypes.string,
  disabledGuidance: PropTypes.bool,
  setDisabledGuidance: PropTypes.func,
  style: PropTypes.object
};

export default Guidance;

import React from 'react';
import PropTypes from 'prop-types';
import { useStorage, useResponsive } from 'utils';

import Card from './Card';

const styles = {
  card: {
    width: '480px',
    margin: '78px 24px'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  }
};

const CardContainer = ({ children, card, cardStyle, containerStyle }) => {
  const [isMobile] = useStorage('isMobile', false);
  const { screenIsUp } = useResponsive();
  const width = window.innerWidth;

  return (screenIsUp('md') && card === undefined) ||
    (isMobile && screenIsUp('sm') && card === undefined) ||
    card ? (
    <Card style={{ ...styles.card, ...cardStyle }}>{children}</Card>
  ) : (
    <div
      style={{
        width: '100%',
        padding: 16,
        marginBottom: width < 768 ? 0 : 50,
        ...containerStyle
      }}
    >
      <div style={styles.wrapper}>{children}</div>
    </div>
  );
};

CardContainer.propTypes = {
  children: PropTypes.any,
  card: PropTypes.bool,
  cardStyle: PropTypes.object,
  containerStyle: PropTypes.object
};

export default CardContainer;

import React from 'react';
import PropTypes from 'prop-types';
import './PasswordCheck.css';
import clsx from 'clsx';

const ProgressBar = ({ className, value }) => {
  const progressProps = {};
  const styles = {};
  if (value !== undefined) {
    progressProps['aria-valuenow'] = Math.round(value);
    const transform = value - 100;
    styles.transform = `translateX(${transform}%)`;
  }
  return (
    <div
      className={clsx('alf-progressbar', className)}
      {...progressProps}
      role="progressbar"
    >
      <div className="progress" style={styles} />
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number,
  className: PropTypes.string
};

export default ProgressBar;

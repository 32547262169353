import React from 'react';
import moment from 'moment';
import { useResponsive } from 'utils';

import { publicFooterLogo } from '../../assets/img';
import { Typography, Button } from '../alf-design';
import './Footer.css';

const Footer = ({ isAuthenticated }) => {
  const { screenIsUp } = useResponsive();
  const logo = publicFooterLogo;

  return (
    <footer className="alf-footer" role="contentinfo">
      <div className="divider">
        <div className="text">
          <div className="title" style={{ marginRight: '8px' }}>
            {isAuthenticated ? 'Need Help?' : 'Difficulty logging in?'}
          </div>

          <div className="title">
            Call Us{' '}
            <a href={`tel:800-748-2030`} className="phone" tabIndex="0">
              (800) 748-2030
            </a>
          </div>
        </div>
        {(screenIsUp('md') || isAuthenticated) && (
          <>
            <div className="buttons-div">
              <Button
                onClick={() => window.open('https://www.slideinsurance.com/')}
                className="button"
                id="visit-website-button"
                link
              >
                Visit our Website
              </Button>
              <Button
                onClick={() =>
                  window.open('https://www.slideinsurance.com/privacy-policy')
                }
                className="button"
                id="privacy-button"
                link
              >
                Privacy Notice
              </Button>
            </div>

            <div className="message">
              Content provided is intended for general informational purposes
              only. Any changes made to your policy may not be immediately
              reflected here. Please refer to your insurance policy for
              coverages, exclusions, and limitations.
            </div>
          </>
        )}
      </div>
      {screenIsUp('md') && (
        <div className="logo">
          <a href="https://www.slideinsurance.com/">
            <img src={logo} width="225" alt="Slide Insurance logo" id="logo" />
          </a>
          <Typography className="copy">
            {`© ${moment().format(
              'YYYY'
            )}, Slide Insurance Holdings. All rights reserved.`}
          </Typography>
        </div>
      )}
    </footer>
  );
};

export default Footer;

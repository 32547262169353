import React, { useState } from 'react';
import { useResponsive, useAuth0 } from 'utils';
import { logo } from 'assets/img';
import {
  Row,
  Col,
  CardContainer,
  Button,
  Typography
} from 'components/alf-design';

const styles = {
  imgContainer: {
    padding: 50,
    display: 'flex',
    justifyContent: 'center'
  },
  img: {
    width: 162,
    height: 35,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  button: {
    marginTop: 70,
    padding: '8px 20px 0'
  },
  buttonSm: {
    margin: '70px 0',
    padding: '10px 20px 0'
  }
};

const AccountUnblocked = () => {
  const [isLoadLogin, setIsLoadLogin] = useState(false);
  const { screenIsDown } = useResponsive();
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    setIsLoadLogin(true);
    try {
      await loginWithRedirect();
    } catch (error) {
      setIsLoadLogin(false);
    }
  };

  return (
    <CardContainer>
      {screenIsDown('md') && (
        <div style={styles.imgContainer}>
          <img style={styles.img} src={logo} alt="Logo" />
        </div>
      )}
      <Typography type="heading3" component="h3">
        Account Unblocked
      </Typography>
      <Typography type="caption1" component="span">
        Your account has been unblocked
      </Typography>

      <Row gutter={24}>
        <Col xs={24}>
          <Button
            withLoading={isLoadLogin}
            type="primary"
            fullWidth
            onClick={handleLogin}
            disabled={isLoadLogin}
            style={screenIsDown('md') ? styles.buttonSm : styles.button}
            link
          >
            Log In
          </Button>
        </Col>
      </Row>
    </CardContainer>
  );
};

export default AccountUnblocked;

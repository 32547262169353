import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Transition } from 'react-transition-group';

import Icon from '../Icon';
import './ExpansionPanel.css';

const duration = 600;

const defaultStyles = {
  height: 0,
  overflow: 'hidden',
  transition: `height ${duration}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`
};

const ExpansionPanel = ({
  className,
  header,
  children,
  onChange,
  expanded: expandedProp,
  headerStyle,
  id
}) => {
  const controlled = !!onChange;

  const [expandedState, setExpanded] = useState(false);
  const [styles, setStyles] = useState(defaultStyles);
  const [focus, setFocus] = useState(false);
  const divRef = createRef();

  const expanded = controlled ? expandedProp : expandedState;

  const handleChange = (e) =>
    controlled ? onChange(e, !expanded) : setExpanded(!expanded);

  const handleEnter = () => setStyles({ height: 0 });

  const handleEntering = () =>
    divRef.current && setStyles({ height: divRef.current.offsetHeight });

  const handleExit = () =>
    setStyles({
      height: 'auto',
      overflow: 'visible'
    });
  const handleExiting = () => setStyles({});
  const handleKeyUp = (e) => {
    if (
      e &&
      ((e.shiftKey && e.keyCode === 9) || e.keyCode === 9 || e.keyCode === 13)
    ) {
      handleChange(e);
    }
  };

  return (
    <div
      className={clsx(
        'alf-panel',
        className,
        expanded && 'expanded',
        focus && 'onFocus'
      )}
    >
      <div
        id={id}
        role="button"
        tabIndex="0"
        className="summary"
        onClick={handleChange}
        onKeyUp={handleKeyUp}
        style={headerStyle}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        aria-controls={children.props.id}
        aria-expanded={!!expanded}
      >
        <div className="content">{header}</div>
        <div className="expand-icon">
          <Icon
            alt="Toggle group"
            name="chevronDown"
            fontSize={14}
            color="#483499"
            onClick={handleChange}
          />
        </div>
      </div>
      <Transition
        appear
        in={expanded}
        timeout={duration}
        onEnter={handleEnter}
        onEntering={handleEntering}
        onExit={handleExit}
        onExiting={handleExiting}
      >
        <div style={{ ...defaultStyles, ...styles }}>
          <div className="details" ref={divRef}>
            {expanded && children}
          </div>
        </div>
      </Transition>
    </div>
  );
};

ExpansionPanel.propTypes = {
  className: PropTypes.string,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  header: PropTypes.any,
  children: PropTypes.any,
  headerStyle: PropTypes.object
};

export default ExpansionPanel;

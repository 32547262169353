import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Col, Tooltip } from 'components/alf-design';
import '../../../alf-design/PolicyRow/PolicyRow.css';
import { formats, useResponsive, customTypes } from 'utils';

const Subtitle = ({ label, children, autoPay }) => {
  const { width } = useResponsive();
  const normalWidth = width > 1550 || (width < 1280 && width > 620);

  return (
    <div style={{ marginBottom: 10 }}>
      <dl style={{ marginTop: normalWidth ? 16 : 0 }}>
        <dt>
          <Typography type={'caption1'} component="span">
            <b>{label}</b>
            {label === 'Payment Plan' && !autoPay && (
              <div style={{ display: 'inline-block' }}>
                <Tooltip
                  display="block"
                  ariaLabel="Payment Information tooltip"
                  text={`To change your Payment Plan, please contact your agent.`}
                />
              </div>
            )}
          </Typography>
        </dt>
        <dd style={{ margin: 0 }}>
          <Typography
            type={'caption1'}
            component="span"
            style={{ textAlign: 'center' }}
          >
            {children}
          </Typography>
        </dd>
      </dl>
    </div>
  );
};

const BillingSubtitles = ({ agent, billing }) => {
  const { width } = useResponsive();
  const normalWidth = width > 1550 || (width < 1280 && width > 620);
  const [paymentPlan, setPaymentPlan] = useState(null);

  useEffect(() => {
    switch (billing.paymentPlan) {
      case 'SEMIANNUAL':
        setPaymentPlan('2 Pay');
        break;
      case 'QUARTERLY':
        setPaymentPlan('4 Pay');
        break;
      case 'ANNUAL':
        setPaymentPlan('Full Pay');
        break;
      default:
        setPaymentPlan(null);
        break;
    }
  }, [billing.paymentPlan]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: normalWidth ? 'row' : 'column',
        justifyContent: 'space-between',
        margin: '0 15px'
      }}
    >
      <Col md={14} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Subtitle label="Payment Plan" autoPay={billing.autoPay}>
          {paymentPlan}
        </Subtitle>
        {billing.nextInstallmentAmount > 0 && (
          <Subtitle label="Next Payment Amount">
            {formats.moneyFormat(billing.nextInstallmentAmount)}
          </Subtitle>
        )}
      </Col>
      <Col md={10} style={{ paddingLeft: 0, paddingRight: 0 }}>
        {billing.nextInstallmentAmount > 0 && (
          <Subtitle label="Next Payment Due Date">
            {formats.dateFormat(billing.nextInstallmentDueDate)}
          </Subtitle>
        )}
      </Col>
    </div>
  );
};

Subtitle.propTypes = {
  label: PropTypes.string,
  children: PropTypes.string
};

BillingSubtitles.propTypes = {
  agent: customTypes.agent,
  billing: customTypes.billing
};

export default BillingSubtitles;

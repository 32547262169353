import React from 'react';
import './PanelRight.css';
import { withRouter } from 'react-router-dom';
import { useResponsive } from 'utils';

import { Row, Col, Typography, Button } from '../alf-design';
import { homeShadow, storm } from '../../assets/icon';

const renderCircleIcon = (icon) => (
  <img alt="" src={icon} style={{ maxWidth: '100%' }} />
);
const PanelRight = () => {
  const { width } = useResponsive();
  const normalWidth = width < 1280 && width > 620;
  return (
    <div className="panel">
      <Row className="first-row">
        {!normalWidth && (
          <Col md={2} className="item-icon">
            {renderCircleIcon(homeShadow)}
          </Col>
        )}
        <Col md={24} xl={16}>
          <Typography type="heading3" component="h2" className="item-subtitle">
            Slide Resource Center
          </Typography>
          {normalWidth && <Col md={2}>{renderCircleIcon(homeShadow)}</Col>}
          <Col md={22}>
            <Typography
              type="paragraph"
              component="p"
              className="item-paragraph"
            >
              Visit our ever-growing collection of articles and tips to help you
              navigate the world of homeownership and insurance.
            </Typography>
          </Col>
          <Col md={24}>
            <Button
              id="slide-resource-center-button"
              whiteBorder
              onClick={() =>
                window.open('https://www.slideinsurance.com/resource-center')
              }
              className="button"
              link
            >
              Learn More
            </Button>
          </Col>
        </Col>
      </Row>
      <Row className="second-row">
        {!normalWidth && (
          <Col md={2} className="item-icon">
            {renderCircleIcon(storm)}
          </Col>
        )}
        <Col md={24} xl={16}>
          <Typography type="heading3" component="h2" className="item-subtitle">
            Storm Center
          </Typography>
          {normalWidth && <Col md={2}>{renderCircleIcon(storm)}</Col>}
          <Col md={22}>
            <Typography
              type="paragraph"
              component="p"
              className="item-paragraph"
            >
              Severe weather can happen at any time. Preparation is vital, and
              it's never too early. Slide’s Storm Center is built to help you
              weather the storm before, during, and after.
            </Typography>
          </Col>
          <Col md={24}>
            <Button
              id="storm-center-button"
              whiteBorder
              onClick={() =>
                window.open('https://www.slideinsurance.com/stormcenter')
              }
              className="button"
              link
            >
              Learn More
            </Button>
          </Col>
        </Col>
      </Row>
    </div>
  );
};
export default withRouter(PanelRight);

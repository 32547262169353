import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

const styles = {
  width: '100%',
  background: '#FEF7F6',
  color: 'rgb(220, 42, 42)',
  textAlign: 'center',
  position: 'fixed',
  padding: 5,
  zIndex: 9999,
  top: 0,
  left: 0
};

const Alert = ({ children, style }) => (
  <div className="alf-alert" style={{ ...styles, ...style }}>
    {children}
  </div>
);

export const showErrorAlert = (props) => {
  const { duration, message, description } = props;
  const div = document.createElement('div');
  document.body.appendChild(div);
  ReactDOM.render(
    <Alert>
      <strong>{message}</strong>
      {` ${description}`}
    </Alert>,
    div
  );
  setTimeout(() => {
    div.parentNode.removeChild(div);
  }, duration || 5000);
  window.scrollTo(0, 0);
  return null;
};

Alert.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object
};

export default Alert;

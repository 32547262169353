import React from 'react';
import { PropTypes } from 'prop-types';
import { Dialog, Typography, Button } from 'components/alf-design';

const styles = {
  actions: { display: 'flex', width: '100%' },
  cancelBtn: { marginRight: 8 },
  title: { textAlign: 'center', marginBottom: 12 }
};

const Confirm = ({
  show,
  title,
  onConfirm,
  toConfirm,
  onClose,
  confirmTxt,
  cancelTxt,
  confirmId,
  cancelId,
  ariaLabel
}) => {
  return (
    <Dialog
      show={show}
      onClose={onClose}
      ariaLabel={ariaLabel}
      actions={
        <div style={styles.actions}>
          <Button
            id={cancelId}
            onClick={onClose}
            style={styles.cancelBtn}
            fullWidth
          >
            {cancelTxt || 'Go Back'}
          </Button>
          <Button
            id={confirmId}
            onClick={onConfirm}
            type="primary"
            link
            fullWidth
            to={toConfirm}
          >
            {confirmTxt || 'Proceed'}
          </Button>
        </div>
      }
    >
      <Typography type="caption4" component="h4" style={styles.title} withTab>
        {title}
      </Typography>
    </Dialog>
  );
};

Confirm.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  confirmTxt: PropTypes.string,
  cancelTxt: PropTypes.string,
  confirmId: PropTypes.string,
  cancelId: PropTypes.string,
  ariaLabel: PropTypes.string
};

export default Confirm;

import React from 'react';
import PropTypes from 'prop-types';
import { check } from 'assets/img';
import './Success.css';

import { Button, Dialog, Typography } from '../../../../alf-design';

const styles = {
  actions: {
    alignSelf: 'center'
  },
  wrapper: {
    width: 490
  }
};

const Success = ({ show, onClose }) => {
  return (
    <Dialog
      id="success-popup"
      show={show}
      onClose={onClose}
      actionsStyle={styles.actions}
      wrapperStyle={styles.wrapper}
      actions={
        <div className="success__div">
          <Button
            id="button-close"
            type="primary"
            onClick={onClose}
            className="success__button"
          >
            Close
          </Button>
        </div>
      }
    >
      <div className="success__content">
        <Typography type="heading3" component="h2" className="success__title">
          Success!
        </Typography>
        <div className="success__icon">
          <img src={check} width="50" alt="check icon" id="check-icon" />
        </div>
        <div style={{ textAlign: 'center' }}>
          <Typography type="caption1">Your updates have been saved.</Typography>
          <Typography type="caption5" className="success__caption5">
            Please allow 24 hours for this update to reflect in your dashboard.
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};

Success.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func
};

export default Success;

export default [
  {
    status: 'Open',
    claimNumber: '2018TX23456',
    policyNumber: '70 44 00007313 00',
    lossDate: '2019-12-05T17:53:15.737Z',
    createdDate: '2019-12-05T17:53:15.737Z',
    adjusterName: 'Suzy MacJuster',
    adjusterPhone: '5555551234',
    adjusterEmail: 'smacjuster@upcinsurance.com',
    inspectionCompleteDate: '2019-12-05T17:53:15.737Z',
    underReviewDate: '2019-12-05T17:53:15.737Z',
    reviewCompleteDate: '2019-12-05T17:53:15.737Z',
    closedWithoutPayment: true,
    closeDate: '2019-12-05T17:53:15.737Z',
    totalLossAmountPaid: 100,
    managerName: 'Manny Jerrison',
    managerPhone: '5551235555',
    managerEmail: 'mjerrison@upcinsurance.com',
    lossStreet: '6772 Maple Terrace',
    lossCity: 'Spring',
    lossState: 'TX',
    lossZip: '77389',
    causeOfLoss: 'Water',
    stage: 'Claim Reported'
  },
  {
    status: 'Open',
    claimNumber: '2018TX34567',
    policyNumber: '70 44 00007313 00',
    lossDate: '2019-12-05T17:53:15.737Z',
    createdDate: '2019-12-05T17:53:15.737Z',
    adjusterName: 'Suzy MacJuster',
    adjusterPhone: '5555551234',
    adjusterEmail: 'smacjuster@upcinsurance.com',
    inspectionCompleteDate: '2019-12-05T17:53:15.737Z',
    underReviewDate: '2019-12-05T17:53:15.737Z',
    reviewCompleteDate: '2019-12-05T17:53:15.737Z',
    closedWithoutPayment: true,
    closeDate: '2019-12-05T17:53:15.737Z',
    totalLossAmountPaid: 100,
    managerName: 'Manny Jerrison',
    managerPhone: '5551235555',
    managerEmail: 'mjerrison@upcinsurance.com',
    lossStreet: '2064 Elm Avenue',
    lossCity: 'Rocky Point',
    lossState: 'NY',
    lossZip: '11778',
    causeOfLoss: 'Water',
    stage: 'Adjuster Assigned'
  },
  {
    status: 'Closed',
    claimNumber: '2018TX45678',
    policyNumber: '70 44 00000078',
    lossDate: '2019-12-05T17:53:15.737Z',
    createdDate: '2019-12-05T17:53:15.737Z',
    adjusterName: 'Suzy MacJuster',
    adjusterPhone: '5555551234',
    adjusterEmail: 'smacjuster@upcinsurance.com',
    inspectionCompleteDate: '2019-12-05T17:53:15.737Z',
    underReviewDate: '2019-12-05T17:53:15.737Z',
    reviewCompleteDate: '2019-12-05T17:53:15.737Z',
    closedWithoutPayment: true,
    closeDate: '2019-12-05T17:53:15.737Z',
    totalLossAmountPaid: 100,
    managerName: 'Manger Name',
    managerPhone: '5555551234',
    managerEmail: 'manager@mock.com',
    lossStreet: '6772 Maple Terrace',
    lossCity: 'Spring',
    lossState: 'TX',
    lossZip: '77389',
    causeOfLoss: 'Water',
    stage: 'Claim Outcome'
  },
  {
    status: 'Open',
    claimNumber: '2018TX44444',
    policyNumber: '32 1006934457 01',
    lossDate: '2019-12-05T17:53:15.737Z',
    createdDate: '2019-12-05T17:53:15.737Z',
    adjusterName: 'Suzy MacJuster',
    adjusterPhone: '5555551234',
    adjusterEmail: 'smacjuster@upcinsurance.com',
    inspectionCompleteDate: '2019-12-05T17:53:15.737Z',
    underReviewDate: '2019-12-05T17:53:15.737Z',
    reviewCompleteDate: '2019-12-05T17:53:15.737Z',
    closedWithoutPayment: true,
    closeDate: '2019-12-05T17:53:15.737Z',
    totalLossAmountPaid: 100,
    managerName: 'Manny Jerrison',
    managerPhone: '5551235555',
    managerEmail: 'mjerrison@upcinsurance.com',
    lossStreet: '6772 Maple Terrace',
    lossCity: 'Spring',
    lossState: 'TX',
    lossZip: '77389',
    causeOfLoss: 'Water',
    stage: 'Claim Reported'
  }
];

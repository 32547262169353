/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useApi, useForm } from 'utils';

import {
  Button,
  Dialog,
  FormField,
  Input,
  Typography,
  showErrorAlert,
  Switch
} from '../../../../alf-design';
import Success from './Success';

import './PreferredEmail.css';

const styles = {
  actions: {
    alignSelf: 'center'
  },
  wrapper: {
    width: 500
  },
  div: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 400
  }
};

const PreferredEmail = ({
  show,
  onClose,
  policyNumber,
  type,
  paperless,
  getPolicy
}) => {
  const handleClose = () => {
    setFormValues({ email: '' });
    onClose();
  };
  const [isLoadLogin, setIsLoadLogin] = useState(false);
  const [showSuccess, toggleSuccess] = useState(false);
  const [title, setTitle] = useState('');
  const validate = (values) => {
    const errors = {};
    const re = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.]+\.[a-zA-Z]+$/;

    if (!values.email && type !== 'Confirm') {
      errors.email = 'Required';
    } else if (values.email.length > 50) {
      errors.email = 'Email must be at most 50 characters long';
    } else if (values.email && !re.test(values.email)) {
      errors.email = 'Please provide a valid email address';
    }
    return errors;
  };
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFormValues
  } = useForm(
    {
      email: '',
      paper: paperless
    },
    validate,
    true
  );
  const api = useApi();
  const onSubmit = (e) => {
    handleSubmit(e, () => {
      setIsLoadLogin(true);
      const { email, paper } = values;
      const body = {
        ...(email !== '' && { Email: email }),
        ...(type === 'Confirm' && { SetPaperless: true }),
        ...(paper && { SetPaperless: true })
      };

      api.withAuth
        .post(`/policies/${policyNumber}/endorsements`, body)
        .then((res) => {
          setIsLoadLogin(false);
          if (email !== '') {
            setFormValues({ email: '' });
            onClose();
            return toggleSuccess(true);
          }
          if (type === 'Confirm' || paper) {
            onClose(true);
          } else {
            onClose();
          }
          getPolicy(policyNumber);
        })
        .catch((err) => {
          setIsLoadLogin(false);
          let message;
          if (err.response) {
            message = err.response.data.Errors[0].value;
          } else if (err.request) {
            message =
              'Network error. Please check your connection and try again.';
          } else {
            message = 'Something went wrong changing your preferred email.';
          }
          showErrorAlert({
            message: err.response?.status || null,
            description: message
          });
          setFormValues({ email: '' });
          if (type === 'Confirm' || paper) {
            onClose(false);
          } else {
            onClose();
          }
        });
    });
  };

  useEffect(() => {
    setFormValues({ email: '', paper: paperless });
    if (type === 'Confirm') {
      setFormValues({ email: '' });
    }
    switch (type) {
      case 'Add':
        setTitle('Add Your Preferred Email');
        break;
      case 'Update':
        setTitle('Update Your Preferred Email');
        break;
      case 'Add Paperless':
      case 'Confirm':
        setTitle('Add Paperless Discount \nand Confirm Preferred Email');
        break;
      case 'Paperless':
      default:
        setTitle('Update Your Preferred Email');
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <>
      <Dialog
        id="preferred-email-popup"
        show={show}
        onClose={onClose}
        actionsStyle={styles.actions}
        wrapperStyle={styles.wrapper}
        actions={
          <div className="preferredEmail__div">
            <Button
              id="button-cancel"
              onClick={handleClose}
              className="preferredEmail__button"
            >
              Cancel
            </Button>

            <Button
              id="button-confirm"
              type="primary"
              onClick={onSubmit}
              className="preferredEmail__button"
              disabled={errors.email ? true : false}
              withLoading={isLoadLogin}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <div className="preferredEmail__content">
          <Typography
            type="heading3"
            component="h2"
            className="preferredEmail__title"
          >
            {title}
          </Typography>
          {(type === 'Add Paperless' || type === 'Confirm') && (
            <div className="preferredEmail__span_confirm">
              <Typography type={'caption1'} component="span">
                <b>Add Paperless Discount</b>
              </Typography>
              <div className="preferredEmail__span_content">
                <Typography>
                  Use electronic delivery of billing and documents.
                </Typography>
                <Switch
                  id="paper"
                  name="paper"
                  containerClassName="flex-col justify-center max-w-sm mx-auto text-center text-bold"
                  handleChange={() => {}}
                  values={true}
                  showYesNo={false}
                />
              </div>
            </div>
          )}
          <FormField label="Email Address" error={errors.email}>
            <Input
              name="email"
              type="email"
              placeholder="Enter your preferred email address"
              id="email-input"
              value={values.email}
              handleBlur={handleBlur}
              success={!errors.email && !!touched.email}
              onChange={handleChange}
            />
          </FormField>
          <Typography type="caption5">
            If you'd like to update the preferred email on multiple policies,
            you must update it on each policy.
          </Typography>
          {(type === 'Paperless' || type === 'Add') && (
            <div className="preferredEmail__span_paperless">
              <Typography type={'caption1'} component="span">
                <b>Add Paperless Discount</b>
              </Typography>
              <div className="preferredEmail__span_content">
                <Typography>
                  Use electronic delivery of billing and documents.
                </Typography>
                <Switch
                  id="paper"
                  name="paper"
                  containerClassName="flex-col justify-center max-w-sm mx-auto text-center text-bold"
                  handleChange={handleChange}
                  values={values.paper}
                  showYesNo={false}
                />
              </div>
            </div>
          )}
        </div>
      </Dialog>

      <Success show={showSuccess} onClose={() => toggleSuccess(false)} />
    </>
  );
};

PreferredEmail.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  policyNumber: PropTypes.string,
  type: PropTypes.string,
  paperless: PropTypes.bool,
  getPolicy: PropTypes.func
};

export default PreferredEmail;

export const STATUS = {
  ACTIVE: 'Active',
  PENDING_CANCEL: 'Pending Cancellation',
  CANCELLED: 'Cancelled'
};

export const METHOD = {
  CARD: 'Card',
  BANK: 'Bank',
  PAPER: 'Paper',
  PENDING_CARD: 'Pending Card',
  PENDING_BANK: 'Pending Bank'
};

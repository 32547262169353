import PropTypes from 'prop-types';

const property = PropTypes.exact({
  street: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string
});

const insured = PropTypes.exact({
  phone: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string
});

const agent = PropTypes.exact({
  organizationName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string
});

const payment = PropTypes.exact({
  policyNumber: PropTypes.string,
  id: PropTypes.number,
  paymentType: PropTypes.shape({}),
  datePaid: PropTypes.string,
  amount: PropTypes.number
});

const billing = PropTypes.oneOfType([
  PropTypes.shape({
    currentDueAmount: PropTypes.number,
    currentDueDate: PropTypes.string,
    amountPaid: PropTypes.number,
    paidDate: PropTypes.string,
    paymentPlan: PropTypes.string,
    nextInstallmentDueDate: PropTypes.string,
    nextInstallmentAmount: PropTypes.number,
    payor: PropTypes.string,
    autoPay: PropTypes.bool,
    paidInFull: PropTypes.bool,
    isMortgagee: PropTypes.bool,
    pastDueAmount: PropTypes.number,
    outstandingBalance: PropTypes.number,
    cancellationReason: PropTypes.string,
    cancellationDate: PropTypes.string,
    mortgageName: PropTypes.string,
    payorPartyId: PropTypes.string,
    accountId: PropTypes.number
  }),
  PropTypes.exact({
    error: PropTypes.string
  })
]);

const coverageItem = PropTypes.exact({
  title: PropTypes.string,
  value: PropTypes.number
});

const policy = PropTypes.shape({
  policyNumber: PropTypes.string,
  status: PropTypes.string,
  price: PropTypes.number,
  company: PropTypes.string,
  type: PropTypes.string,
  effectiveDate: PropTypes.string,
  expirationDate: PropTypes.string,
  property,
  insured,
  agent,
  payment,
  coverages: PropTypes.arrayOf(coverageItem),
  deductibles: PropTypes.arrayOf(coverageItem),
  fnol: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.shape({
      url: PropTypes.string
    })
  })
});

const claim = PropTypes.exact({
  claimNumber: PropTypes.string,
  policyNumber: PropTypes.string,
  status: PropTypes.string,
  lossDate: PropTypes.string,
  createdDate: PropTypes.string,
  closeDate: PropTypes.string,
  causeOfLoss: PropTypes.string,
  stage: PropTypes.string,
  adjusterName: PropTypes.string,
  adjusterPhone: PropTypes.string,
  adjusterEmail: PropTypes.string,
  inspectionCompleteDate: PropTypes.string,
  underReviewDate: PropTypes.string,
  reviewCompleteDate: PropTypes.string,
  closedWithoutPayment: true,
  totalLossAmountPaid: 0,
  managerName: PropTypes.string,
  managerPhone: PropTypes.string,
  managerEmail: PropTypes.string,
  lossLocation: PropTypes.exact({
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string
  })
});

const api = PropTypes.shape({
  withAuth: PropTypes.shape({
    get: PropTypes.func,
    patch: PropTypes.func,
    post: PropTypes.func
  })
});

export default {
  claim,
  api,
  policy,
  payment,
  billing,
  agent
};

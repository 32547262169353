import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Button, Dialog } from '../alf-design';

const styles = {
  dialog: {
    maxWidth: 389,
    textAlign: 'center'
  },
  actions: {
    justifyContent: 'center'
  },
  price: {
    marginTop: 12,
    marginBottom: 12,
    color: '#DC2A2A'
  },
  button: {
    margin: '24px 0',
    width: 260
  }
};

const OverdueNotification = ({ show, price, onPayment, onClose }) => {
  return (
    <div>
      <Dialog
        id="overdue-popup"
        show={show}
        onClose={onClose}
        wrapperStyle={styles.dialog}
        actionsStyle={styles.actions}
        actions={
          <Button
            onClick={onPayment}
            type="secondary"
            style={styles.button}
            id="overdue-payment-button"
            link
          >
            Make a Payment
          </Button>
        }
      >
        <Typography type="subtitle2" component="p" style={{ marginTop: 24 }}>
          You have an overdue payment of:
        </Typography>
        <Typography type="heading3" component="h3" style={styles.price}>
          {`$${price}`}
        </Typography>
        <Typography type="subtitle2" component="p">
          To avoid policy cancellation, please remit payment immediately
        </Typography>
      </Dialog>
    </div>
  );
};

OverdueNotification.propTypes = {
  show: PropTypes.bool,
  price: PropTypes.number,
  onClose: PropTypes.func,
  onPayment: PropTypes.func
};

export default OverdueNotification;

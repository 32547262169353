import React from 'react';
import PropTypes from 'prop-types';
import './Discount.css';
import { useResponsive } from 'utils';

import { Button, Dialog, Typography } from '../../../../alf-design';

const Discount = ({ show, onClose }) => {
  const { width } = useResponsive();
  const smallScreen = width < 768;
  const styles = {
    actions: {
      alignSelf: 'center',
      marginTop: !smallScreen && 30,
      marginBottom: 20
    },
    wrapper: {
      width: !smallScreen && 690,
      padding: 10,
      height: smallScreen && '100%'
    },
    content: {
      margin: !smallScreen && '0px 25px'
    }
  };

  return (
    <>
      <Dialog
        id="preferred-email-popup"
        show={show}
        onClose={onClose}
        actionsStyle={styles.actions}
        wrapperStyle={styles.wrapper}
        contentStyle={styles.content}
        actions={
          <Button
            id="button-cancel"
            onClick={onClose}
            type="primary"
            className="discount__button"
          >
            Close
          </Button>
        }
      >
        <Typography type="heading3" component="h1" className="discount__title">
          Consent of Electronic Delivery
        </Typography>
        <div className="discount__children">
          <Typography
            type="caption3"
            component="h2"
            className="discount__subtitle"
          >
            Acknowledgment of Consent to Electronic Delivery
          </Typography>
          <Typography type="paragraph" className="discount__paragraph">
            I consent to accept delivery of this insurance policy and all
            comunications regarding this policy through electronic means. My
            consent applies to all policy forms, notices, and communications
            until I reject my consent to electronic delivery. I understand that
            such electronic delivery communications may include any notice of
            termination, cancellation, nonrenewal, or premium increases. I
            certify that I have access to a device suitable for connecting to
            the internet, an up-to-date internet browser, a valid email account,
            means to digitally store electronic communications sent to me, and
            software that enables me to view files in Portable Document Format
            (PDF). I understand that I must notify my insurance carrier of a
            change to my email address in order to continue to receive my policy
            forms and communications electronically. I understand that I may
            withdraw my consent to delivery at any time, and that doing so will
            remove any discounts associated with using electronic delivery. I
            understand that withdrawing my consent to electronic delivery may
            result in a increase in my premium. I understand that withdrawing my
            consent does not affect the legal validity, effectiveness, or
            enforceability of any of any policy form or communication sent to me
            prior to my withdrawal of consent. If I withraw my consent to
            electronic delivery, all policy forms and communications will be
            delivered to me in paper form by mail. I understand that I have
            right to obtain a copy of any policy form or communication made
            available and sent to me in paper form. I may request a paper copy
            of a form or communication, or withdraw my consent to electronic
            delivery, by contacting my agent or customer service representative
            by phone, email, or written communication.
          </Typography>
        </div>
      </Dialog>
    </>
  );
};

Discount.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func
};

export default Discount;

export default [
  {
    id: 1,
    amounPaid: 2571.94,
    datePaid: '2020-07-20',
    last4CheckOrCardNum: '7779',
    lastUpdatedTimestamp: null,
    paymentType: 'Credit Card',
    postMarkDate: '2020-07-23',
    status: 'Active',
    policyNumber: '70 44 00007313 00'
  },
  {
    id: 2,
    amounPaid: 1112.94,
    datePaid: '2020-07-05',
    last4CheckOrCardNum: '7779',
    lastUpdatedTimestamp: '2020-07-23',
    paymentType: 'Credit Card',
    postMarkDate: '2020-07-23',
    status: 'Reversed',
    policyNumber: '70 44 00007313 00'
  },
  {
    id: 3,
    amounPaid: 4333.94,
    datePaid: '2020-07-02',
    last4CheckOrCardNum: '7779',
    lastUpdatedTimestamp: null,
    paymentType: 'eCheck',
    postMarkDate: '2020-07-23',
    status: 'Submitted',
    policyNumber: '70 44 00007313 00'
  }
];

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { formats, useResponsive } from 'utils';

const LinkedPolicies = ({ policies, history }) => {
  const { screenIsDown } = useResponsive();
  const handleSelect = (policy) => {
    history.replace(`/?policy=${policy}`);
  };

  return policies.map((p) => (
    <li
      className="user-profile-policy"
      style={{
        color: '#6648DC',
        fontSize: screenIsDown('sm')
          ? 'min(15rem, calc(16px * 1.5))'
          : 'min(22rem, calc(22px * 1.5))'
      }}
      key={p.policyNumber}
    >
      <a onClick={() => handleSelect(p.policyNumber)}>
        {`${p.propertyStreet}, ${p.propertyCity}, ${
          p.propertyState
        } ${formats.zipFormat(p.propertyZip)}`}
      </a>
    </li>
  ));
};

LinkedPolicies.propTypes = {
  policies: PropTypes.array
};

export default withRouter(LinkedPolicies);

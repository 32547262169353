import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './Card.css';

const Card = ({ style, children, className }) => (
  <div className={clsx('alf-card', className)} style={style}>
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string
};

export default Card;

import React from 'react';
import PropTypes from 'prop-types';

import { Button, Card, Typography, Col, Row } from '../alf-design';

import './NoPolicies.css';

const NoPolicies = ({ handleNewPolicy, error }) => {
  const errorMessage =
    error && error.entity === 'policy'
      ? 'We are experiencing some problems fetching your policy information. Please try again later.'
      : 'We are experiencing some problems finding policies for your account. Please try again later.';

  return (
    <Row type="flex" justify="center">
      <Col xs={24} sm={18} md={14} className="no-policies__card">
        <Card style={{ width: 'min-width' }}>
          {error === null ? (
            <>
              <Typography
                type="subtitle3"
                component="h4"
                className="no-policies__heading"
              >
                You have no linked policies.
              </Typography>
              <Button
                type="primary"
                className="no-policies__link"
                link
                onClick={handleNewPolicy}
              >
                Link a Policy
              </Button>
              <Typography className="no-policies__help">
                If you need further assistance, please contact Customer Care at{' '}
                <a
                  href="tel:8007482030"
                  style={{ color: '#6648DC', textDecoration: 'none' }}
                >
                  (800) 748-2030
                </a>
                .
              </Typography>
            </>
          ) : (
            <>
              <Typography type="subtitle3" className="no-policies__help">
                {errorMessage}
              </Typography>
              <Typography
                className="no-policies__help"
                style={{ marginTop: 20 }}
              >
                If you need immediate assistance, please contact Customer
                Service at{' '}
                <a
                  href="tel:8007482030"
                  style={{ color: '#6648DC', textDecoration: 'none' }}
                >
                  (800) 748-2030
                </a>
                .
              </Typography>
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

NoPolicies.propTypes = {
  handleNewPolicy: PropTypes.func,
  error: PropTypes.shape({
    entity: PropTypes.string,
    err: PropTypes.any
  })
};

export default NoPolicies;
